import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Header from '../Layouts/Header';
import Login from '../components/Auth/Login';
import Register from '../components/Auth/Register';
import ForgotPassword from '../components/Auth/ForgotPassword';
import RegisterSuccess from '../components/Auth/RegisterSuccess';
import VerifyAccount from '../components/Auth/VerifyAccount';
import NotFound from '../components/Auth/NotFound';

const PublicRoutes = ({ match }) => (

	<Fragment>
		<Header />
		<div className="public-pages">
			<Container className="full-height">
	            <Row className="full-height align-items-center">
					<Switch>			
						<Route exact path="/" component={Login} />
						<Route exact path="/login" component={Login} />
						<Route exact path="/register" component={Register} />
						<Route exact path="/forgot-password" component={ForgotPassword} />
						<Route exact path="/register-success" component={RegisterSuccess} />
						<Route exact path="/verify-account/:member_key" component={VerifyAccount} />
						<Route component={NotFound} />
					</Switch>
				</Row>
			</Container>
		</div>
	</Fragment>
);

export default PublicRoutes;
