import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Icofont from 'react-icofont';
import LoadingSpinner from '../partials/LoadingSpinner';

class RegisterSuccess extends Component {
    constructor() {
        super();
    }
    render() {
        return (
            <Col sm={{ span: 6, offset: 3 }}>
                <div className="msg-info">                    
                    <center>  
                        <h2 style={{margin: "20px"}}>Register Success <span style={{color: "green", fontSize: "40px"}}><Icofont icon="send-mail" /></span></h2>
                        <p>Thankyou. We have sent you email to your registered email address. Please click the link in that message to activate your account!</p>
                        <Link to={'/login'}><Icofont icon="long-arrow-left" /> Login</Link>                  
                    </center>
                </div>
            </Col>                                 
        )
    }
}

export default RegisterSuccess;   
