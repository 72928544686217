import { authHeader, appApi } from '../helpers';
const role=localStorage.getItem('role');

export const alladminApi = (request) => {
    const ADMIN_LIST = appApi + "" + role +"/admin-list";
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(ADMIN_LIST, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};



