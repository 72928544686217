import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { createMember } from '../../actions';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';
import MemberForm from './MemberForm';

class MemberCreate extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            userAvator:null
        };

        this.memberformHandler = this.memberformHandler.bind(this)
    }
    memberformHandler(formData){
        this.props.dispatch(createMember(formData));
    }
    render() {
        const { toast_key, history } = this.props;
        if(toast_key==="member_created"){
            //toast.success("Member created successfully!");
            history.push('/' + this.state.authRole + '/member-list');
        }

        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <Link to={'/' + this.state.authRole + '/dashboard'}><span className="inner-lefticon"><Icofont icon="thin-left" /></span> Home</Link>
                </Col>
                <MemberForm memberformHandler={this.memberformHandler} data={null}/>                                
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {    
        toast_key: state.members.toast_key,    
        message: state.members.data.message    
    }
}

export default connect(mapStateToProps)(MemberCreate);   
