import axios from 'axios';
import { authHeader, appApi } from '../helpers';
const role=localStorage.getItem('role');

export const allmemberApi = (request) => {
    const MEMBER_LIST = appApi + "" + role + "/member-list?sort_by=" + request.sortBy;
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(MEMBER_LIST, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const createMemberApi = (request) => {
    const CREATE_MEMBER_API = appApi + "" + role + "/member-create";
    const parameters = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_MEMBER_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const togglememberApi = (member_id) => {
    const MEMBER_TOGGLE_API = appApi + "" + role + "/member-toggle/" + member_id; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(MEMBER_TOGGLE_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const profilememberApi = (member_id) => {
    const MEMBER_PROFILE_API = appApi + "" + role + "/member-edit/" + member_id; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(MEMBER_PROFILE_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const myprofileApi = (member_key) => {
    const MEMBER_PROFILE_API = appApi + "" + role + "/my-profile/" + member_key; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(MEMBER_PROFILE_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const myprofileupdateApi = (request) => {
    let token = localStorage.getItem('token');
    let formdata=request.formData;
    const UPDATE_MY_PROFILE = appApi + "" + role + "/my-profile-update";

    let fdata=new FormData();
    fdata.append("avator", formdata.avator);
    fdata.append("firstname", formdata.firstname);
    fdata.append("lastname", formdata.lastname);
    fdata.append("email", formdata.email);
    fdata.append("phone", formdata.phone);
    fdata.append("member_key", formdata.member_key);

    axios({
        method: "post",
        url: UPDATE_MY_PROFILE,
        data: fdata,
        headers: { 
            "Authorization": "Bearer " + token,
            "Content-Type": "multipart/form-data" 
        },
    }).then(function (response) {
        return response.json();
    }).catch(function (response) {
        return response;
    });
};
export const updatememberApi = (request) => {
    const UPDATE_MEMBER_API = appApi + "" + role + "/member-update";
    const parameters = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request.formData)
    };

    return fetch(UPDATE_MEMBER_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const connectstripeApi = (request) => {
    const CONNECT_STRIPE_API = appApi + "" + role + "/stripe-connect";
    const parameters = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request.formData)
    };

    return fetch(CONNECT_STRIPE_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const mymembershipApi = (member_key) => {
    const MY_MEMBERSHIP_API = appApi + "" + role + "/my-membership/" + member_key; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(MY_MEMBERSHIP_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const createmembershipApi = (member_key) => {
    const CREATE_MEMBERSHIP_API = appApi + "" + role + "/create-membership/" + member_key; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(CREATE_MEMBERSHIP_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const cancelmembershipApi = (member_key) => {
    const CANCEL_MEMBERSHIP_API = appApi + "" + role + "/cancel-membership/" + member_key; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(CANCEL_MEMBERSHIP_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const deletememberApi = (member_id) => {
    const DELETE_MEMBER_API = appApi + "" + role + "/member-delete/" + member_id; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(DELETE_MEMBER_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const memberinvoiceApi = (invoice_key) => {
    const GET_INVOICE_API = appApi + "" + role + "/my-invoices/" + invoice_key; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GET_INVOICE_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};