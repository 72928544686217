import { authHeader, appApi } from '../helpers';
const role=localStorage.getItem('role');

export const createmeetingApi = (pod_id) => {
    const CREATE_MEETING = appApi + "" + role + "/create-meeting/" + pod_id;
    const parameters = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(CREATE_MEETING, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
