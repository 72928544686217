import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Icofont from 'react-icofont';

class AdminCreate extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            userAvator:null
        };

        this.avatorChange = this.avatorChange.bind(this)
    }
    avatorChange(event) {
        this.setState({
            userAvator: URL.createObjectURL(event.target.files[0])
        })
    }
    render() {
        return (
            <Row>
                <Col sm={12} className="home-title">
                    <Link to={'/' + this.state.authRole + '/dashboard'}><span className="inner-lefticon"><Icofont icon="thin-left" /></span> Home</Link>
                </Col>
                <Col sm={{ span: 6, offset: 3 }}>
                    <Form className="page-form">
                        <Row className="mb-3">
                            <Col sm={2}>
                                <Form.Group className="custom-upload">
                                    <Form.Control type="file" onChange={this.avatorChange}/>
                                    <img className="custom-preview" src={this.state.userAvator} />
                                </Form.Group>
                            </Col>
                            <Col sm={10}>
                                <Form.Group>
                                    <Form.Label>Click to change your image</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" name="firstname" id="firstname" />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" name="lastname" id="lastname" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" name="email" id="email" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="text" name="password" id="password" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col sm={12} className="text-end">
                                <Button type="submit" className="submit-link">Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>                                       
            </Row>
        )
    }
}
  
export default AdminCreate;
