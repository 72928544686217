import Logo from '../Logo.svg';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { appLogout } from '../actions';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Icofont from 'react-icofont';

class Header extends Component {
    constructor(props){
        super(props);
   
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            authRole:localStorage.getItem('role'),
        };

        this.handleLogout = this.handleLogout.bind(this);
    }
    handleLogout(e){
        e.preventDefault();    
        const formData = {
            'token': localStorage.getItem('token')
        };
        this.props.dispatch(appLogout(formData));
    }
    render() {
        const { toast_key } = this.props;
        const { authRole, user } = this.state;

        if (toast_key==='logged_out') { 
            localStorage.clear();
            toast.success('Successfully logged out!');
            window.location = '/login'; 
        }

        return (
            <Navbar className="main-navbar">
                <Container>
                    { authRole==='admin'? (
                        <a href={'/' + authRole + '/dashboard'} className="navbar-brand">
                            <img alt="" src={Logo} className="d-inline-block align-top" width="22" height="22"/> 
                            SOCIAL PODS
                        </a>
                    ) :(
                        <a href={authRole==="facilitator" || authRole ==="member"? "/" + authRole + "/group-list" : "/"}  className="navbar-brand">
                            <img alt="" src={Logo} className="d-inline-block align-top" width="22" height="22"/> 
                            SOCIAL PODS
                        </a>
                    )}
                    
                    { authRole!==null? (
                        <Nav className="justify-content-end d-flex align-items-center">
                            <Nav.Link href="#home" className="logged-notify">
                                <Icofont icon="alarm" />
                            </Nav.Link>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" drop="start" className="logged-user">
                                    <img src={user.avator} alt="avator"/>
                                    <span className="logged-status"></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="animate slideIn">
                                    { authRole==='admin'? (
                                        <Dropdown.Item href={'/admin/admin-profile'}>Profile</Dropdown.Item>
                                    ) : null }
                                    { authRole==='facilitator'? (
                                        <Dropdown.Item href={'/facilitator/facilitator-profile/' + user.id}>Profile</Dropdown.Item>
                                    ) : null }
                                    { authRole==='member'? (
                                        <Dropdown.Item href={'/member/my-profile/' + user.member_key}>Profile</Dropdown.Item>
                                    ) : null }                                    
                                    <Dropdown.Item onClick={this.handleLogout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    ) : null}                    
                </Container>
            </Navbar>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        toast_key: state.auth.toast_key,      
        message: state.auth.message 
    }
}
 
export default connect(mapStateToProps)(Header);   
