import { meetConstants } from '../store/constants';

export const createMeeting = (pod_id) => {
    return {
        type: meetConstants.CREATE_MEETING,
        pod_id
    }
};

export const joinMeeting = () => {
    return {
        type: meetConstants.JOIN_MEETING
    }
};

export const endMeeting = () => {
    return {
        type: meetConstants.END_MEETING
    }
};

export const liveMeeting = (metingID) => {
    return {
        type: meetConstants.LIVE_MEETING,
        metingID
    }
};

