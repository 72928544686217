import { groupConstants } from '../store/constants';

export const fetchGroups = () => {
    return {
        type: groupConstants.GROUPS
    }
};
export const fetchGroupinfo = (id) => {
    return {
        type: groupConstants.GROUPINFO,
        id
    }
};
export const createGroup = (formData) => {
    return {
        type: groupConstants.CREATE_GROUP,
        formData
    }
};
export const updateGroup = (formData) => {
    return {
        type: groupConstants.UPDATE_GROUP,
        formData
    }
};
export const createPod = (formData) => {
    return {
        type: groupConstants.CREATE_POD,
        formData
    }
};
export const updatePod = (formData) => {
    return {
        type: groupConstants.UPDATE_POD,
        formData
    }
};
export const podInfo = (group_id, pod_id) => {
    return {
        type: groupConstants.POD_INFO,
        group_id,
        pod_id
    }
};
export const addFacilitator = (formData) => {
    return {
        type: groupConstants.ADD_FACILITATOR,
        formData
    }
};
export const removeFacilitator = (formData) => {
    return {
        type: groupConstants.REMOVE_FACILITATOR,
        formData
    }
};
export const subscribeGroup = (group_id) => {
    return {
        type: groupConstants.SUBSCRIBE_GROUP,
        group_id
    }
};
export const unsubscribeGroup = (group_id) => {
    return {
        type: groupConstants.UNSUBSCRIBE_GROUP,
        group_id
    }
};




