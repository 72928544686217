import React, { useMemo, useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
// import AddressSection from './AddressSection';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

const useOptions = () => {
    const fontSize = "16px";
    const options = useMemo(() => ({
        style: {
            base: {
                fontSize,
                color: "#424770",
                letterSpacing: "0.025em",
                fontFamily: "Source Code Pro, monospace",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#9e2146"
            }
        }
    }),
    [fontSize]
    );

    return options;
};

const CheckoutForm = ({helloStripe}) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const [endpoint, setEndpoint] = useState(null);

    const handleSubmit = async event => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement)
        });

        if (payload.error) {
            // Show error to your customer (e.g., payment details incomplete)
            //console.log(payload.error.message);
            toast.error(payload.error.message);
            return;
        } 

        const token=payload?payload.paymentMethod.id : null;
        helloStripe(token);
    };

    return (
        <form onSubmit={handleSubmit} className="page-form stripe-form">
            <Row className="mb-3">
                <Col sm={12}>
                    <Form.Group>
                        <Form.Label>Card Number</Form.Label>
                        <CardNumberElement
                            options={options}
                            onReady={() => {
                                // console.log("CardNumberElement [ready]");
                            }}
                            onChange={event => {
                                // console.log("CardNumberElement [change]", event);
                            }}
                            onBlur={() => {
                                // console.log("CardNumberElement [blur]");
                            }}
                            onFocus={() => {
                                // console.log("CardNumberElement [focus]");
                            }}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label>Expiration</Form.Label>
                        <CardExpiryElement options={options}/>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label>Security Code</Form.Label>
                        <CardCvcElement options={options} required={true}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={12}>
                    <button type="submit" disabled={!stripe}>Save & Subscribe</button>
                </Col>
            </Row>
            <Toaster />
        </form>
    );
};

export default CheckoutForm;

