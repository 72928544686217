import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Icofont from 'react-icofont';
import { podInfo, createMeeting } from '../../actions';
import LoadingSpinner from '../partials/LoadingSpinner';
import HomeLink from '../partials/HomeLink';
import VideoBox from './VideoBox';
import toast, { Toaster } from 'react-hot-toast';
import PodCounter from './PodCounter';
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

class PodInfo extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            user: JSON.parse(localStorage.getItem('user')),
            group_id:this.props.match.params.group_id,
            pod_id:this.props.match.params.pod_id,
            podinfo:[],
            openLoader:false,
            meeting_url:''
        };

        this.createMeet = this.createMeet.bind(this);
    }
    createMeet(){
        this.props.dispatch(createMeeting(this.state.pod_id));
        this.setState({
            openLoader:true
        });
    }
    joinMeet(){
        let join_url=this.state.podinfo.member_join;
        this.setState({
            meeting_url:join_url,
            openLoader:true
        });
    }
    errorMeet(type){
        if(type==="permission"){
            toast("Hey Facilitator! You don't have permission to create the Meeting!",{
                    duration: 9000,
                }
            );
        }
        else if(type==="trail"){
            toast("Hey Member! Your tril period over!",{
                    duration: 9000,
                }
            );
        }
        else if(type==="free"){
            toast("Hey Member! Your free access period over!",{
                    duration: 9000,
                }
            );
        }   
        else if(type==="not_started"){
            toast("Hey Member! This meeting not started yet Please wait few minutes!",{
                    duration: 9000,
                }
            );
        }          
    }
    componentDidMount() {
        this.props.dispatch(podInfo(this.state.group_id, this.state.pod_id));
        // var timezone_offset_minutes = new Date().getTimezoneOffset();
        // timezone_offset_minutes = timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;
        // console.log(timezone_offset_minutes); 

        // const permissions = navigator.mediaDevices.getUserMedia({audio: true, video: true});
        // permissions.then((stream) => {
        // }).catch((err) => {
        // });
    }
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                podinfo:this.props.data
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState({
                meeting_url:nextProps.facilitator_meet_url
            });
        }
    }

    render() {
        
        const { authRole, group_id, podinfo, openLoader, user, meeting_url, member_join } = this.state;
        const { loading, toast_key } = this.props;        
        const hoursMinSecs = { 
            hours:podinfo.meeting_remaining?podinfo.meeting_remaining.h : 0, 
            minutes: podinfo.meeting_remaining?podinfo.meeting_remaining.i : 0, 
            seconds: podinfo.meeting_remaining?podinfo.meeting_remaining.s : 0
        }
        
        const PodDetails = () => {
            return(
                <Col sm={{ span: authRole!=='member'? 8 : 6, offset: authRole!=='member'? 2 : 3 }} className="f-height">
                    <Row className="full-height align-items-center">
                        <Col sm={authRole!=='member'? 8 : 12} className="pod-info group-info">
                            <Row className="mb-2">
                                <Col sm={12}>                                    
                                    <h4>{podinfo.topic}</h4>
                                    <p>Members only <Icofont icon="ui-alarm" /></p>
                                </Col>
                            </Row>   
                            <Row className="mb-3">
                                <Col sm={12}>
                                    <p>Details</p>
                                    <p className="group-p">{podinfo.description}</p>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={12}>
                                    <p>{podinfo.pod_interests} interests</p>                                    
                                </Col>
                            </Row>  
                            <Row className="align-items-center">
                                <Col sm={6}>
                                    <ul className="facilitator-group">
                                        <li>                                           
                                            {authRole==='admin'? (
                                                <Fragment>
                                                    <div>
                                                        <label><i className="icofont-minus-circle"></i></label>
                                                        <img src={podinfo.pod_avator} alt="avator" />
                                                    </div>
                                                    <span>{user.name}</span>
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <div>
                                                        <img src={podinfo.pod_avator} alt="avator" />
                                                    </div>
                                                    <span>{podinfo.pod_own_by}</span>
                                                </Fragment>
                                            )}                                            
                                        </li>
                                    </ul>
                                </Col>
                                <Col sm={6} className="text-end">
                                    <Row>
                                        { podinfo.pod_date==='completed' && authRole==='facilitator'? ( 
                                            <Col sm={12}>
                                                <h4 className="text-center">Ended at {podinfo.time} {podinfo.day}, {podinfo.event_start}</h4>
                                                <h4 className="text-center">{podinfo.timezone}</h4>
                                                <span className="meet-link">Show Recording</span>     
                                            </Col>
                                            ) : null
                                        }
                                        { podinfo.pod_date==='upcoming' && authRole==='facilitator'? ( 
                                            <Col sm={12}>
                                                <h4 className="text-center">Starts at {podinfo.time} {podinfo.day}, {podinfo.event_start}</h4>   
                                                <h4 className="text-center">{podinfo.timezone}</h4>
                                            </Col>
                                            ) : null
                                        } 
                                        { podinfo.pod_date==='today' && authRole==='facilitator'? (  
                                            <Col sm={12}>
                                                {podinfo.meeting_status? <PodCounter hoursMinSecs={hoursMinSecs}/> : (
                                                    <>
                                                        <h4 className="text-center">Starts at {podinfo.time} {podinfo.day}, {podinfo.event_start}</h4>
                                                        <h4 className="text-center">{podinfo.timezone}</h4>
                                                    </>
                                                )}         
                                                <Row>
                                                    <Col sm={12}>
                                                    {user.id===podinfo.created_by?(
                                                        <span className="meet-link" onClick={() => this.createMeet()}>
                                                            {openLoader? 'Loading ...' : podinfo.meeting_status? 'Continue Session' : 'Start Session' }
                                                        </span>
                                                        ) : podinfo.meeting_status? (
                                                            <span className="meet-link" onClick={() => this.joinMeet()}>
                                                                {openLoader? 'Loading ...' : 'Join Session'}
                                                            </span>
                                                        ) : (
                                                            <span className="meet-link" onClick={() => this.errorMeet("permission")}>
                                                                {openLoader? 'Loading ...' : 'Join Session ' } 
                                                            </span>
                                                        )
                                                    }                                                     
                                                    </Col>  
                                                </Row> 
                                            </Col>
                                            ) : null
                                        }

                                        { podinfo.pod_date==='completed' && authRole==='member'? (  
                                            <Col sm={12}> 
                                                {podinfo.meeting_status? <PodCounter hoursMinSecs={hoursMinSecs}/> : (
                                                    <>
                                                    <h4 className="text-center">Starts at {podinfo.time} {podinfo.day}, {podinfo.event_start}</h4>
                                                    <h4 className="text-center">{podinfo.timezone}</h4>
                                                    </>
                                                )}                                                     
                                                <Row>  
                                                    <Col sm={12}>
                                                    { podinfo.member_subscription==="free_7_days"? (
                                                        <Link className="new-link" to="#" to={'/member/member-payment/' + user.member_key}>Subscribe</Link>
                                                        ) : (
                                                        <span className="meet-link">Show Recording</span>
                                                        )}                                            
                                                    </Col>  
                                                </Row> 
                                            </Col>
                                            ) : null
                                        }
                                        { podinfo.pod_date==='today' && authRole==='member'? (  
                                            <Col sm={12}> 
                                                {podinfo.meeting_status? <PodCounter hoursMinSecs={hoursMinSecs}/> : (
                                                    <>
                                                    <h4 className="text-center">Starts at {podinfo.time} {podinfo.day}, {podinfo.event_start}</h4>
                                                    <h4 className="text-center">{podinfo.timezone}</h4>
                                                    </>
                                                )}                                                     
                                                <Row>  
                                                    <Col sm={12}>
                                                    { podinfo.member_subscription==="free_7_days"? (
                                                        <Link className="new-link" to="#" to={'/member/member-payment/' + user.member_key}>Subscribe</Link>
                                                        ) : podinfo.meeting_status? (
                                                            <span className="meet-link" onClick={() => this.joinMeet()}>
                                                                {openLoader? 'Loading ...' : 'Join Session'}
                                                            </span>
                                                        ) : (
                                                            <span className="meet-link" onClick={() => this.errorMeet("not_started")}>
                                                                {openLoader? 'Loading ...' : 'Join Session'}
                                                            </span>
                                                        )}                                            
                                                    </Col>  
                                                </Row> 
                                            </Col>
                                            ) : null
                                        }
                                        { podinfo.pod_date==='upcoming' && authRole==='member'? (  
                                            <Col sm={12}> 
                                                {podinfo.meeting_status? <PodCounter hoursMinSecs={hoursMinSecs}/> : (
                                                    <>
                                                    <h4 className="text-center">Starts at {podinfo.time} {podinfo.day}, {podinfo.event_start}</h4>
                                                    <h4 className="text-center">{podinfo.timezone}</h4>
                                                    </>
                                                )}                                                     
                                                <Row>  
                                                    <Col sm={12}>
                                                    { podinfo.member_subscription==="free_7_days"? (
                                                        <Link className="new-link" to="#" to={'/member/member-payment/' + user.member_key}>Subscribe</Link>
                                                        ) : null}                                            
                                                    </Col>  
                                                </Row> 
                                            </Col>
                                            ) : null
                                        }
                                    </Row>
                                </Col>
                            </Row>  
                        </Col>
                        { authRole!=='member'? (
                            <Col sm={4}>
                                <div className="inner-box">
                                    <ul>
                                        <div>Pod Health</div>
                                        <li>Group Subscriptions</li>
                                        <li>Interests</li>
                                        <li>Attendees</li>
                                        <li>Drops</li>
                                    </ul>
                                    { podinfo.pod_date!=='completed'? ( 
                                        <Link className="new-link" to={'/' + authRole + '/pod-edit/' + group_id + '/' + podinfo.id}>Edit Pod</Link>
                                    ) : null }                                
                                </div>
                            </Col>
                        ) : <></>}
                    </Row>
                </Col> 
            )
        }

        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <HomeLink role={authRole}/>
                </Col>  
                {loading ? <LoadingSpinner /> : <PodDetails/>} 
                {openLoader ? <embed allow="camera *;microphone *;fullscreen *;" className="bbb_embed" src={meeting_url} width="100%" /> : null} 
                <Toaster />                                   
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.groups.loading,     
        data: state.groups.data, 
        facilitator_meet_url: state.meetings.facilitator_meet_url,          
        toast_key: state.meetings.toast_key
    }
}

export default connect(mapStateToProps)(PodInfo);
