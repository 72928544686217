import React, { Component } from 'react';
import { connect } from 'react-redux';
import { appVerify } from '../../actions';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Icofont from 'react-icofont';
import LoadingSpinner from '../partials/LoadingSpinner';

class VerifyAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            member_key: this.props.match.params.member_key,
        };
    }
    componentDidMount() {
        this.props.dispatch(appVerify(this.state.member_key));
    }
    render() {
        const { loading, toast_key, user} = this.props;
        return (
            <Col sm={{ span: 6, offset: 3 }}>
                {loading ? <LoadingSpinner /> : (
                    <div className="msg-info">                    
                        <center>  
                            <h2 style={{margin: "20px"}}>Account Verified <span style={{color: "green", fontSize: "40px"}}><Icofont icon="safety" /></span></h2>
                            <p>Your account has been successfully verified. Thankyou</p>
                            <Link to={'/login'}><Icofont icon="long-arrow-left" /> Login</Link>
                        </center>
                    </div>                    
                )}                                    
            </Col>                                 
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        toast_key: state.auth.toast_key,        
        user: state.auth.user,        
    }
}

export default connect(mapStateToProps)(VerifyAccount); 
