import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';
import Iframe from 'react-iframe';

class VideoBox extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            toggle_videoBox:false
        };

        this.toggleBox = this.toggleBox.bind(this)
    }
    componentDidMount() {
        
    }
    toggleBox() {
        this.setState({ 
            toggle_videoBox: ! this.state.toggle_videoBox 
        });
    }
    render() {
        const { authRole, toggle_videoBox } = this.state;
        const { openLoader, meeting_url } = this.props;

        return (
            <>
                { meeting_url? (
                    <div className="video-box">
                        <Iframe url={meeting_url}
                            width="100%"
                            height="100%"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"
                            allow="camera *;microphone *;"
                            //allow="camera *;microphone *;geolocation *;midi *;encrypted-media *"
                        />
                    </div> 
                    ) : null                
                }
            </>                       
        )
    }
}

export default VideoBox

