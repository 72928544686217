import {put, call, takeEvery} from 'redux-saga/effects';
import { verifyApi, registerApi, loginApi, logoutApi } from '../../services/authServices';
import { authConstants } from '../constants';

export function* verifySaga(payload) {
    try {
        const response = yield call(verifyApi, payload.member_key);
        yield put({ type: authConstants.VERIFY_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: authConstants.VERIFY_FAILURE, error })
    }
}
export function* registerSaga(payload) {
    try {
        const response = yield call(registerApi, payload);
        yield put({ type: authConstants.REGISTER_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: authConstants.REGISTER_FAILURE, error })
    }
}
export function* loginSaga(payload) {
    try {
        const response = yield call(loginApi, payload);
        yield put({ type: authConstants.LOGIN_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: authConstants.LOGIN_FAILURE, error })
    }
}
export function* logoutSaga(payload) {
    try {
        const response = yield call(logoutApi, payload);
        yield put({ type: authConstants.LOGOUT_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: authConstants.LOGOUT_FAILURE, error })
    }
}

export default function* authSaga() {
    yield takeEvery(authConstants.REGISTER_REQUEST, registerSaga);
    yield takeEvery(authConstants.LOGIN_REQUEST, loginSaga);
    yield takeEvery(authConstants.LOGOUT, logoutSaga);
    yield takeEvery(authConstants.VERIFY_REQUEST, verifySaga);
}