import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { createMember } from '../../actions';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';
import MemberForm from './MemberForm';

class MemberEdit extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            userAvator:null
        };

        this.memberformHandler = this.memberformHandler.bind(this)
    }

    memberformHandler(formData){
        this.props.dispatch(createMember(formData));
    }

    render() {
        const { status, history } = this.props;
        if(status){
            toast.success("Member created successfully!");
            history.push('/' + this.state.authRole + '/member-list');
        }

        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <Link to={'/' + this.state.authRole + '/dashboard'}><span className="inner-lefticon"><Icofont icon="thin-left" /></span> Home</Link>
                </Col>

                <MemberForm memberformHandler={this.memberformHandler}/>                               
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {    
        status: state.members.data.status,    
        message: state.members.data.message    
    }
}

export default connect(mapStateToProps)(MemberEdit);   
