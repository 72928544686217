export const memberConstants = {
    MEMBERS: 'ALL_MEMBERS',
    MEMBERS_SUCCESS: 'ALL_MEMBERS_SUCCESS',
    MEMBERS_FAILURE: 'ALL_MEMBERS_FAILURE',

    CREATE_MEMBER: 'CREATE_NEWMEMBER',
    CREATE_MEMBER_SUCCESS: 'CREATE_NEWMEMBER_SUCCESS',
    CREATE_MEMBER_FAILURE: 'CREATE_NEWMEMBER_FAILURE',

    TOGGLE_MEMBER: 'TOGGLE_STATUSMEMBER',
    TOGGLE_MEMBER_SUCCESS: 'TOGGLE_STATUSMEMBER_SUCCESS',
    TOGGLE_MEMBER_FAILURE: 'TOGGLE_STATUSMEMBER_FAILURE',

    PROFILE_MEMBER: 'PROFILE_MEMBER',
    PROFILE_MEMBER_SUCCESS: 'PROFILE_MEMBER_SUCCESS',
    PROFILE_MEMBER_FAILURE: 'PROFILE_MEMBER_FAILURE', 

    MY_PROFILE: 'MY_PROFILE',
    MY_PROFILE_SUCCESS: 'MY_PROFILE_SUCCESS',
    MY_PROFILE_FAILURE: 'MY_PROFILE_FAILURE',  

    UPDATE_MEMBER: 'UPDATE_MEMBER',
    UPDATE_MEMBER_SUCCESS: 'UPDATE_MEMBER_SUCCESS',
    UPDATE_MEMBER_FAILURE: 'UPDATE_MEMBER_FAILURE',

    UPDATE_MYPROFILE: 'UPDATE_MYPROFILE',
    UPDATE_MYPROFILE_SUCCESS: 'UPDATE_MYPROFILE_SUCCESS',
    UPDATE_MYPROFILE_FAILURE: 'UPDATE_MYPROFILE_FAILURE',

    CONNECT_STRIPE: 'CONNECT_STRIPE',
    STRIPE_MEMBER_SUCCESS: 'STRIPE_MEMBER_SUCCESS',
    STRIPE_MEMBER_FAILURE: 'STRIPE_MEMBER_FAILURE',

    MY_MEMBERSHIP: 'MY_MEMBERSHIP',
    MEMBERSHIP_SUCCESS: 'MY_MEMBERSHIP_SUCCESS',
    MEMBERSHIP_FAILURE: 'MY_MEMBERSHIP_FAILURE',

    CREATE_MEMBERSHIP: 'CREATE_MEMBERSHIP',
    CREATE_MEMBERSHIP_SUCCESS: 'CREATE_MEMBERSHIP_SUCCESS',
    CREATE_MEMBERSHIP_FAILURE: 'CREATE_MEMBERSHIP_FAILURE',

    CANCEL_MEMBERSHIP: 'CANCEL_MEMBERSHIP',
    CANCEL_MEMBERSHIP_SUCCESS: 'CANCEL_MEMBERSHIP_SUCCESS',
    CANCEL_MEMBERSHIP_FAILURE: 'CANCEL_MEMBERSHIP_FAILURE',

    DELETE_MEMBER: 'DELETE_MEMBER',
    DELETE_MEMBER_SUCCESS: 'DELETE_MEMBER_SUCCESS',
    DELETE_MEMBER_FAILURE: 'DELETE_MEMBER_FAILURE',

    GET_INVOICE: 'GET_INVOICE',
    GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
    GET_INVOICE_FAILURE: 'GET_INVOICE_FAILURE',
};