import {put,call,takeLatest} from 'redux-saga/effects'
import { 
    allgroupApi, 
    groupinfoApi, 
    creategroupApi, 
    createpodApi, 
    addfacilitatorApi, 
    removefacilitatorApi,
    podinfoApi,
    updategroupApi, 
    updatepodApi,
    subscribegroupApi,
    unsubscribegroupApi 
} from '../../services/groupServices';
import { groupConstants } from '../constants';

export function* allgroupSaga(payload) {
    try {
        const response = yield call(allgroupApi, payload);
        yield put({ type: groupConstants.GROUPS_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: groupConstants.GROUPS_FAILURE, error })
    }
}
export function* groupinfoSaga(payload) {
    try {
        const response = yield call(groupinfoApi, payload.id);
        yield put({ type: groupConstants.GROUPINFO_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: groupConstants.GROUPINFO_FAILURE, error })
    }
}
export function* creategroupSaga(payload) {
    try {
        const response = yield call(creategroupApi, payload);
        yield put({ type: groupConstants.CREATE_GROUP_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: groupConstants.CREATE_GROUP_FAILURE, error })
    }
}
export function* createpodSaga(payload) {
    try {
        const response = yield call(createpodApi, payload);
        yield put({ type: groupConstants.CREATE_POD_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: groupConstants.CREATE_POD_FAILURE, error })
    }
}
export function* addfacilitatorSaga(payload) {
    try {
        const response = yield call(addfacilitatorApi, payload);
        yield put({ type: groupConstants.ADD_FACILITATOR_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: groupConstants.ADD_FACILITATOR_FAILURE, error })
    }
}
export function* removefacilitatorSaga(payload) {
    try {
        const response = yield call(removefacilitatorApi, payload);
        yield put({ type: groupConstants.REMOVE_FACILITATOR_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: groupConstants.REMOVE_FACILITATOR_FAILURE, error })
    }
}
export function* podinfoSaga(payload) {
    try {
        const response = yield call(podinfoApi, payload.group_id, payload.pod_id);
        yield put({ type: groupConstants.POD_INFO_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: groupConstants.POD_INFO_FAILURE, error })
    }
}
export function* updategroupSaga(payload) {
    try {
        const response = yield call(updategroupApi, payload);
        yield put({ type: groupConstants.UPDATE_GROUP_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: groupConstants.UPDATE_GROUP_FAILURE, error })
    }
}
export function* updatepodSaga(payload) {
    try {
        const response = yield call(updatepodApi, payload);
        yield put({ type: groupConstants.UPDATE_POD_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: groupConstants.UPDATE_POD_FAILURE, error })
    }
}
export function* subscribegroupSaga(payload) {
    try {
        const response = yield call(subscribegroupApi, payload.group_id);
        yield put({ type: groupConstants.SUBSCRIBE_GROUP_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: groupConstants.SUBSCRIBE_GROUP_FAILURE, error })
    }
}
export function* unsubscribegroupSaga(payload) {
    try {
        const response = yield call(unsubscribegroupApi, payload.group_id);
        yield put({ type: groupConstants.UNSUBSCRIBE_GROUP_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: groupConstants.UNSUBSCRIBE_GROUP_FAILURE, error })
    }
}
export default function* groupSaga() {
    yield takeLatest(groupConstants.GROUPS, allgroupSaga);
    yield takeLatest(groupConstants.GROUPINFO, groupinfoSaga);
    yield takeLatest(groupConstants.CREATE_GROUP, creategroupSaga);
    yield takeLatest(groupConstants.UPDATE_GROUP, updategroupSaga);
    yield takeLatest(groupConstants.CREATE_POD, createpodSaga);
    yield takeLatest(groupConstants.ADD_FACILITATOR, addfacilitatorSaga);
    yield takeLatest(groupConstants.REMOVE_FACILITATOR, removefacilitatorSaga);
    yield takeLatest(groupConstants.POD_INFO, podinfoSaga);
    yield takeLatest(groupConstants.UPDATE_POD, updatepodSaga);
    yield takeLatest(groupConstants.SUBSCRIBE_GROUP, subscribegroupSaga);
    yield takeLatest(groupConstants.UNSUBSCRIBE_GROUP, unsubscribegroupSaga);
}