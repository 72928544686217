export const groupConstants = {
    GROUPS: 'ALL_GROUPS',
    GROUPS_SUCCESS: 'ALL_GROUPS_SUCCESS',
    GROUPS_FAILURE: 'ALL_GROUPS_FAILURE',

    GROUPINFO: 'ONE_GROUPINFO',
    GROUPINFO_SUCCESS: 'ONE_GROUPINFO_SUCCESS',
    GROUPINFO_FAILURE: 'ONE_GROUPINFO_FAILURE',

    CREATE_GROUP: 'CREATE_NEWGROUP',
    CREATE_GROUP_SUCCESS: 'CREATE_NEWGROUP_SUCCESS',
    CREATE_GROUP_FAILURE: 'CREATE_NEWGROUP_FAILURE', 

    UPDATE_GROUP: 'UPDATE_ONEGROUP',
    UPDATE_GROUP_SUCCESS: 'UPDATE_ONEGROUP_SUCCESS',
    UPDATE_GROUP_FAILURE: 'UPDATE_ONEGROUP_FAILURE',

    CREATE_POD: 'CREATE_NEWPOD',
    CREATE_POD_SUCCESS: 'CREATE_NEWPOD_SUCCESS',
    CREATE_POD_FAILURE: 'CREATE_NEWPOD_FAILURE', 

    UPDATE_POD: 'UPDATE_ONEPOD',
    UPDATE_POD_SUCCESS: 'UPDATE_ONEPOD_SUCCESS',
    UPDATE_POD_FAILURE: 'UPDATE_ONEPOD_FAILURE',

    ADD_FACILITATOR: 'ADD_FACILITATOR_TOGROUP',
    ADD_FACILITATOR_SUCCESS: 'ADD_FACILITATOR_TOGROUP_SUCCESS',
    ADD_FACILITATOR_FAILURE: 'ADD_FACILITATOR_TOGROUP_FAILURE',

    REMOVE_FACILITATOR: 'REMOVE_FACILITATOR_TOGROUP',
    REMOVE_FACILITATOR_SUCCESS: 'REMOVE_FACILITATOR_TOGROUP_SUCCESS',
    REMOVE_FACILITATOR_FAILURE: 'REMOVE_FACILITATOR_TOGROUP_FAILURE',

    POD_INFO: 'POD_INFO',
    POD_INFO_SUCCESS: 'ONE_PODINFO_SUCCESS',
    POD_INFO_FAILURE: 'ONE_PODINFO_FAILURE',

    SUBSCRIBE_GROUP: 'SUBSCRIBE_GROUP',
    SUBSCRIBE_GROUP_SUCCESS: 'SUBSCRIBE_GROUP_SUCCESS',
    SUBSCRIBE_GROUP_FAILURE: 'SUBSCRIBE_GROUP_FAILURE',

    UNSUBSCRIBE_GROUP: 'UNSUBSCRIBE_GROUP',
    UNSUBSCRIBE_GROUP_SUCCESS: 'UNSUBSCRIBE_GROUP_SUCCESS',
    UNSUBSCRIBE_GROUP_FAILURE: 'UNSUBSCRIBE_GROUP_FAILURE',
};