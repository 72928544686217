import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import toast from 'react-hot-toast';
import { Formik } from "formik";
import * as Yup from "yup";
import axios from"axios";
import { appApi } from '../../helpers';
import LoadingSpinner from '../partials/LoadingSpinner';

const RegisterForm = ({registerformHandler, history}) => (

    <Formik
        enableReinitialize
        initialValues={{ 
            firstname: '', 
            lastname: '', 
            email: '', 
            phone: '', 
            password: '',
            address_line1:'',
            address_line2:'',
            city:'',
            state:'',
            pincode:''
        }}
        onSubmit={(values, { setErrors, setSubmitting }) => {
            setTimeout(() => {
                (async () => {
                    try {
                        const result = await axios.post(appApi + "register", values);
                        toast.success("Member created successfully!");
                        history.push('/register-success');                       
                    } catch (exception) {
                        // Expected: 400 status code
                        if (exception.response && exception.response.status === 400) {
                            let form_errors=exception.response.data.errors;
                            setErrors(form_errors);
                            setSubmitting(false);
                        }
                    }
                })();
            }, 500);
        }}
        >
        {props => {
            const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit
            } = props;

            console.log(isSubmitting);
            { isSubmitting && <LoadingSpinner />}

            return (
                
                <Form className="page-form" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <h4 className="page-title">Apply for membership</h4>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="firstname" 
                                    id="firstname" 
                                    value={values.firstname} 
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                    isValid={touched.firstname && !errors.firstname}
                                    className={(errors.firstname && touched.firstname ? 'is-invalid' : '')}/> 
                                    <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>                                   
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control 
                                type="text" 
                                name="lastname" 
                                value={values.lastname} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.lastname && !errors.lastname}
                                className={(errors.lastname && touched.lastname ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>  
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Email Id</Form.Label>
                                <Form.Control 
                                type="email" 
                                name="email" 
                                value={values.email} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.email && !errors.email}
                                className={(errors.email && touched.email ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>  
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control 
                                type="text" 
                                name="phone" 
                                value={values.phone} 
                                onChange={handleChange}
                                onBlur={handleBlur} 
                                isValid={touched.phone && !errors.phone}
                                className={(errors.phone && touched.phone ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback> 
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control 
                                type="password" 
                                name="password" 
                                value={values.password} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.password && !errors.password}
                                className={(errors.password && touched.password ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback> 
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3 d-none">
                        <Col sm={12}>
                            <Form.Group className="custom-document">                                
                                <Form.Control type="file" id="document" name="document" />
                                <img className="custom-preview" alt=""/>                                
                            </Form.Group>
                        </Col>
                    </Row>    
                    <Row className="mb-3 mt-4">
                        <h4 className="page-title">Address</h4>
                    </Row>                
                    <Row className="mb-3">
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>Address Line 1</Form.Label>
                                <Form.Control 
                                type="text" 
                                name="address_line1" 
                                value={values.address_line1} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.address_line1 && !errors.address_line1}
                                className={(errors.address_line1 && touched.address_line1 ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.address_line1}</Form.Control.Feedback> 
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>Address Line 2</Form.Label>
                                <Form.Control 
                                type="text" 
                                name="address_line2" 
                                value={values.address_line2} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.address_line2 && !errors.address_line2}
                                className={(errors.address_line2 && touched.address_line2 ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.address_line2}</Form.Control.Feedback> 
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={4}>
                            <Form.Group>
                                <Form.Label>City</Form.Label>
                                <Form.Control 
                                type="text" 
                                name="city" 
                                value={values.city} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.city && !errors.city}
                                className={(errors.city && touched.city ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback> 
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group>
                                <Form.Label>State</Form.Label>
                                <Form.Control 
                                type="text" 
                                name="state" 
                                value={values.state} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.state && !errors.state}
                                className={(errors.state && touched.state ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback> 
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group>
                                <Form.Label>Pincode</Form.Label>
                                <Form.Control 
                                type="text" 
                                name="pincode" 
                                value={values.pincode} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.pincode && !errors.pincode}
                                className={(errors.pincode && touched.pincode ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.pincode}</Form.Control.Feedback> 
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12} className="text-end">
                            <Button type="submit" className="submit-link">Submit</Button>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12} className="text-end">
                            <p className="vs">Already have an account? <Link to={'/login'}><b>Login</b></Link></p> 
                        </Col>
                    </Row>
                </Form>
            );
        }}
    </Formik>
);

export default RegisterForm;
