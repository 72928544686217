import { memberConstants } from '../store/constants';

export const fetchMembers = (sortBy) => {
    return {
        type: memberConstants.MEMBERS,
        sortBy
    }
};
export const createMember = (formData) => {
    return {
        type: memberConstants.CREATE_MEMBER,
        formData
    }
};
export const profileMember = (member_id) => {
    return {
        type: memberConstants.PROFILE_MEMBER,
        member_id
    }
};
export const myProfile = (member_key) => {
    return {
        type: memberConstants.MY_PROFILE,
        member_key
    }
};
export const updateMember = (formData) => {
    return {
        type: memberConstants.UPDATE_MEMBER,
        formData
    }
};
export const updateMyprofile = (formData) => {
    return {
        type: memberConstants.UPDATE_MYPROFILE,
        formData
    }
};
export const toggleMember = (member_id) => {
    return {
        type: memberConstants.TOGGLE_MEMBER,
        member_id
    }
};
export const connectStripe = (formData) => {
    return {
        type: memberConstants.CONNECT_STRIPE,
        formData
    }
};
export const myMembership = (member_key) => {
    return {
        type: memberConstants.MY_MEMBERSHIP,
        member_key
    }
};
export const createMembership = (member_key) => {
    return {
        type: memberConstants.CREATE_MEMBERSHIP,
        member_key
    }
};
export const cancelMembership = (member_key) => {
    return {
        type: memberConstants.CANCEL_MEMBERSHIP,
        member_key
    }
};
export const deleteMember = (member_id) => {
    return {
        type: memberConstants.DELETE_MEMBER,
        member_id
    }
};
export const memberInvoices = (invoice_key) => {
    return {
        type: memberConstants.GET_INVOICE,
        invoice_key
    }
};