import { authConstants } from '../store/constants';

export const appVerify = (member_key) => {
    return {
        type: authConstants.VERIFY_REQUEST,
        member_key
    }
};
export const appRegister = (formData) => {
    return {
        type: authConstants.REGISTER_REQUEST,
        formData
    }
};
export const appLogin = (formData) => {
    return {
        type: authConstants.LOGIN_REQUEST,
        formData
    }
};
export const appLogout = (formData) => {
    return {
        type: authConstants.LOGOUT,
        formData
    }
};