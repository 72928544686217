const subscriptionMiddleware = (store) => (next) => (action) => {
    
    switch(action.type) {
        // only catch a specific action
        case 'FETCH_MOVIE_DATA':
            // continue propagating the action through redux
            // this is our only call to next in this middleware
            next(action);
            
        break
 
        // if we don't need to handle this action, we still need to pass it along
        default: next(action)
    }
}
 
export default subscriptionMiddleware;