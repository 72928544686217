import { Route, Switch, Redirect } from 'react-router-dom';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';
import {Toaster} from 'react-hot-toast';
import EndMeeting from './components/Auth/EndMeeting';

const role = localStorage.getItem('role');
const admin_default="/" + role + "/dashboard";
const other_default="/" + role + "/group-list";
const OpenRoutes = () => role !== null ? (
    <Redirect to={role==="admin"? admin_default : other_default } />
) : (
    <PublicRoutes/>
);

function App() {
    return (                 
        <Switch>
            <Route exact path="/end-meeting" component={EndMeeting} />
            <Route path={"/"+ role} component={PrivateRoutes}/>
            <Route path="/" render={OpenRoutes} />            
            <Toaster />
        </Switch>   
    )
}


export default App;