import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { createMember, memberInvoices } from '../../actions';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Icofont from 'react-icofont';
import MemberForm from './MemberForm';
import Logo from '../../Logo.svg';
import LoadingSpinner from '../partials/LoadingSpinner';
import Moment from 'moment';

class MyInvoice extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            invoice_key: this.props.match.params.invoice_key,
        };
    }
    componentDidMount() {
        this.props.dispatch(memberInvoices(this.state.invoice_key));
    }

    render() {
        const { history, loading, invoice } = this.props;

        return (
            <Container>                
                <Col sm={{ span: 8, offset: 2 }}>
                    { loading? (
                        <LoadingSpinner />
                        ) : (
                            <>
                                <div className="my-invoice">
                                    <div className="invoice-top">
                                        <Row className="mb-4">
                                            <Col sm={7}>
                                                <img src={Logo} />
                                            </Col>
                                            <Col sm={5}>
                                                <div className="mb-4 address">
                                                    <p>Socialpods, LLc</p>
                                                    <p>Las vegas, Nevada</p>
                                                    <p>United States</p>
                                                </div>
                                                <div className="address">
                                                    <p>@ vineetkumar@mysocialpods.com</p>
                                                    <p>m +1270-937-0152</p>
                                                </div>                                
                                            </Col>
                                        </Row>
                                        <Row className="mt-4 mb-4">
                                            <Col sm={7}>
                                                <h4>RECIPIENT</h4>
                                                <div className="mb-4 address">
                                                    <p className="mt-2">Socialpods, LLc</p>
                                                    <p>LAS VEGAS,Nevada</p>
                                                    <p>United States</p>
                                                </div>
                                                <div className="address">
                                                    <p>@ vineetkumar@mysocialpods.com</p>
                                                    <p>m +1270-937-0152</p>
                                                </div>   
                                            </Col>
                                            <Col sm={5} className="address">
                                                <h4>INVOICES</h4>
                                                <h5 className="mt-3">Invoice no</h5>
                                                <p>{invoice.number}</p>
                                                <h5 className="mt-3">Payment Date</h5>
                                                <p>{Moment(invoice.period_start).format("LL")}</p>
                                                <h5 className="mt-3">Payment Method</h5>
                                                <p>{invoice.collection_method}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="invoice-bottom">
                                        <Row className="mt-4 mb-4">
                                            <Table size="sm invoice-table">
                                                <thead>
                                                    <tr>
                                                        <th>Description</th>
                                                        <th>Quantity in weeks</th>
                                                        <th>Amount</th>
                                                        <th>Date of paid</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Socialpods Premium Account</td>
                                                        <td>1</td>
                                                        <td>25 $</td>
                                                        <td>{Moment(invoice.period_start).format("LL")}</td>
                                                        <td></td>
                                                    </tr>   
                                                    <tr>
                                                        <td colSpan="2" className="text-end">SUBTOTAL</td><td colSpan="2" className="text-end">25 $</td>
                                                    </tr> 
                                                    <tr>
                                                        <td colSpan="2" className="text-end">DISCOUNT 0%</td><td colSpan="2" className="text-end">00</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2" className="text-end">TAX 0%</td><td colSpan="2" className="text-end">00</td>
                                                    </tr> 
                                                    <tr>
                                                        <td colSpan="2" className="text-end">TOTAL</td><td colSpan="2" className="text-end">25$</td>
                                                    </tr>                                 
                                                </tbody>
                                            </Table>
                                        </Row>
                                    </div>                                
                                </div>
                                <Row className="mt-3 mb-4 text-center">
                                    <Link to={invoice.invoice_pdf} type="application/pdf" download target="_blank" className="subscribe-link inline">Download Invoice</Link>
                                </Row>
                            </>
                        ) }                    
                </Col>        
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {    
        loading: state.members.loading,    
        invoice: state.members.invoice    
    }
}

export default connect(mapStateToProps)(MyInvoice);   
