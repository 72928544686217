import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Icofont from 'react-icofont';
import { Scrollbars } from 'react-custom-scrollbars';
import { fetchAdmins } from '../../actions';
import LoadingSpinner from '../partials/LoadingSpinner';

class AdminList extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
        };
    }
    componentDidMount() {
        this.props.dispatch(fetchAdmins());
    }

    render() {
        const { data, loading } = this.props;
        const { authRole } = this.state;
        const AdminTable = () => {
            return(
                <div className="table-responsive">
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Admin Since</th>
                                <th>Approval Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.length > 0 ? data.map(admin => (
                                <tr key={admin.id}>
                                    <td data-label="">
                                        <div className="flex-avator">&nbsp;</div>
                                    </td>
                                    <td data-label="Name">{admin.name}</td>
                                    <td data-label="Email">{admin.email}</td>
                                    <td data-label="Member Since">{admin.created_at}</td>
                                    <td data-label="Approval Status">{(admin.status==='active')? 'Approved' : 'Pending'}</td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="5">
                                        Empty Admins ...
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        }
            
        return (
            <Row>
                <Col sm={12} className="home-title">
                    <Link to={'/' + authRole + '/dashboard'}><span className="inner-lefticon"><Icofont icon="thin-left" /></span> Home</Link>
                </Col>
                <Col sm={{ span: 10, offset: 1 }}>
                    <Row>
                        <Col sm={9} className="inner-scroll">
                            <Row className="mb-2 align-items-center">
                                <Col sm={8} className="table-title">Admins</Col>
                                <Col sm={2} className="text-end font-xs">Sort By</Col>
                                <Col sm={2}>
                                    <Form.Select size="sm">
                                        <option>Name</option>
                                        <option>Email</option>
                                        <option>Status</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Scrollbars style={{ width: '100%', height: '100%' }}
                            autoHide
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            thumbMinSize={10}>  

                            {loading ? <LoadingSpinner /> : <AdminTable />}
                                
                            </Scrollbars>
                        </Col>
                        <Col sm={3}>
                            <div className="inner-box">
                                <ul>
                                    <div>Admins</div>
                                    <li>Total</li>
                                    <li>Assigned</li>
                                    <li>Un Assigned</li>
                                </ul>
                                <Link className="new-link" to={'#'} >Add Admin</Link>
                            </div>
                        </Col>
                    </Row>
                </Col>                                       
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.admins.loading,     
        data: state.admins.data.data    
    }
}

export default connect(mapStateToProps)(AdminList); 

