import { memberConstants } from '../constants';

const initialState = {
    toggleStatus:false,
    toast_key:'',
    status:false,
    message:'',
    loading: false, 
    data:[],
    membership:[],
    invoice:[]
};

export default function memberReducer( state=initialState, {type, response}) {

    switch(type) {
        case memberConstants.MEMBERS:
            return { 
                ...state,
                loading:true 
            };
        case memberConstants.MEMBERS_SUCCESS:
            return { 
                ...state, 
                loading:false, 
                toast_key:'',                
                data:response.data
            };
        case memberConstants.MEMBERS_FAILURE:
            return { 
                ...state,
                loading:false 
            };

        case memberConstants.CREATE_MEMBER:
            return { 
                ...state,
                loading:true 
            };
        case memberConstants.CREATE_MEMBER_SUCCESS:
            return { 
                ...state, 
                loading:false,                 
                toast_key:response.toast_key,
                data:response.data
            };
        case memberConstants.CREATE_MEMBER_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case memberConstants.TOGGLE_MEMBER:
            return { 
                ...state,
                loading:false 
            };
        case memberConstants.TOGGLE_MEMBER_SUCCESS:
            return { 
                ...state, 
                toggleStatus:response.status,
                loading:false,                 
            };
        case memberConstants.TOGGLE_MEMBER_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case memberConstants.PROFILE_MEMBER:
            return { 
                ...state,
                loading:true 
            };
        case memberConstants.PROFILE_MEMBER_SUCCESS:
            return { 
                ...state, 
                data:response.data,
                loading:false                 
            };
        case memberConstants.PROFILE_MEMBER_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case memberConstants.MY_PROFILE:
            return { 
                ...state,
                loading:true 
            };
        case memberConstants.MY_PROFILE_SUCCESS:
            return { 
                ...state, 
                data:response.data,
                loading:false                 
            };
        case memberConstants.MY_PROFILE_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case memberConstants.UPDATE_MEMBER:
            return { 
                ...state,
                loading:false 
            };
        case memberConstants.UPDATE_MEMBER_SUCCESS:
            return { 
                ...state, 
                loading:false,
                toast_key:response.toast_key,                 
            };
        case memberConstants.UPDATE_MEMBER_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case memberConstants.UPDATE_MYPROFILE:
            return { 
                ...state,
                loading:false 
            };
        case memberConstants.UPDATE_MYPROFILE_SUCCESS:
            return { 
                ...state, 
                loading:false,
                toast_key:response.toast_key,                 
            };
        case memberConstants.UPDATE_MYPROFILE_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case memberConstants.CONNECT_STRIPE:
            return { 
                ...state,
                loading:true 
            };
        case memberConstants.STRIPE_MEMBER_SUCCESS:
            return { 
                ...state, 
                loading:false,
                toast_key:response.toast_key, 
                membership:response.customer                 
            };
        case memberConstants.STRIPE_MEMBER_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case memberConstants.MY_MEMBERSHIP:
            return { 
                ...state,
                loading:true 
            };
        case memberConstants.MEMBERSHIP_SUCCESS:
            return { 
                ...state, 
                loading:false,
                toast_key:response.toast_key,                 
                membership:response.customer               
            };
        case memberConstants.MEMBERSHIP_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case memberConstants.CREATE_MEMBERSHIP:
            return { 
                ...state,
                loading:true 
            };
        case memberConstants.CREATE_MEMBERSHIP_SUCCESS:
            return { 
                ...state, 
                loading:false,
                toast_key:response.toast_key,                 
                membership:response.customer               
            };
        case memberConstants.CREATE_MEMBERSHIP_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case memberConstants.CANCEL_MEMBERSHIP:
            return { 
                ...state,
                loading:true 
            };
        case memberConstants.CANCEL_MEMBERSHIP_SUCCESS:
            return { 
                ...state, 
                loading:false,
                toast_key:response.toast_key,                 
                membership:response.customer               
            };
        case memberConstants.CANCEL_MEMBERSHIP_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case memberConstants.DELETE_MEMBER:
            return { 
                ...state,
                loading:false 
            };
        case memberConstants.DELETE_MEMBER_SUCCESS:
            return { 
                ...state, 
                toast_key:response.toast_key,
                loading:false,                 
            };
        case memberConstants.DELETE_MEMBER_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case memberConstants.GET_INVOICE:
            return { 
                ...state,
                loading:true 
            };
        case memberConstants.GET_INVOICE_SUCCESS:
            return { 
                ...state, 
                invoice:response.invoice,
                loading:false                 
            };
        case memberConstants.GET_INVOICE_FAILURE:
            return { 
                ...state,
                loading:false
            };
                
        default:
            return state;
    }
};
