import axios from 'axios';
import { authHeader, appApi } from '../helpers';
const role=localStorage.getItem('role');

export const allfacilitatorApi = (request) => {
    const FACILITATOR_LIST = appApi + "" + role + "/facilitator-list?sort_by=" + request.sortBy;
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(FACILITATOR_LIST, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const createFacilitatorApi = (request) => {
    const CREATE_FACILITATOR_API = appApi + "" + role + "/facilitator-create";
    let token = localStorage.getItem('token');
    let formdata=request.formData;
    let fdata=new FormData();
    fdata.append("avator", formdata.avator);
    fdata.append("firstname", formdata.firstname);
    fdata.append("lastname", formdata.lastname);
    fdata.append("email", formdata.email);
    fdata.append("phone", formdata.phone);
    fdata.append("phone", formdata.phone);
    fdata.append("password", formdata.password);
    fdata.append("linkedin_profile", formdata.linkedin_profile);

    const parameters = {
        method: 'POST',
        headers: { 
            "Authorization": "Bearer " + token,
        },
        body:fdata
    };

    return fetch(CREATE_FACILITATOR_API, parameters).then(response => {
        return response.json(response);
    }).then(json => {
        return json;
    });
};
export const updateFacilitatorApi = (request) => {
    const UPDATE_FACILITATOR_API = appApi + "" + role + "/facilitator-update";
    // const parameters = {
    //     method: 'POST',
    //     headers: authHeader(),
    //     body: JSON.stringify(request.formData)
    // };

    let token = localStorage.getItem('token');
    let formdata=request.formData;
    let fdata=new FormData();
    fdata.append("avator", formdata.avator);
    fdata.append("firstname", formdata.firstname);
    fdata.append("lastname", formdata.lastname);
    fdata.append("email", formdata.email);
    fdata.append("phone", formdata.phone);
    fdata.append("phone", formdata.phone);
    fdata.append("password", formdata.password);
    fdata.append("linkedin_profile", formdata.linkedin_profile);
    fdata.append("facilitator_id", formdata.facilitator_id);

    const parameters = {
        method: 'POST',
        headers: { 
            "Authorization": "Bearer " + token,
        },
        body:fdata
    };

    return fetch(UPDATE_FACILITATOR_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const togglefacilitatorApi = (facilitator_id) => {
    const FACILITATOR_TOGGLE_API = appApi + "" + role + "/facilitator-toggle/" + facilitator_id; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(FACILITATOR_TOGGLE_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const profilefacilitatorApi = (facilitator_id) => {
    const FACILITATOR_PROFILE_API = appApi + "" + role + "/my-profile/" + facilitator_id; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(FACILITATOR_PROFILE_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};

export const myprofilefacilitatorApi = (facilitator_id) => {
    const FACILITATOR_PROFILE_API = appApi + "" + role + "/facilitator-profile/" + facilitator_id; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(FACILITATOR_PROFILE_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};

export const deletefacilitatorApi = (facilitator_id) => {
    const FACILITATOR_DELETE_API = appApi + "" + role + "/facilitator-delete/" + facilitator_id; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(FACILITATOR_DELETE_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};



