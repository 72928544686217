import { authHeader, appApi } from '../helpers';
const role=localStorage.getItem('role');

export const allgroupApi = (request) => {
    const GROUP_LIST = appApi + "" + role + "/group-list";
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GROUP_LIST, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const groupinfoApi = (id) => {
    const GROUP_INFO = appApi + "" + role + "/group-info/" + id; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GROUP_INFO, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const creategroupApi = (request) => {
    const CREATE_GROUP_API = appApi + "" + role + "/group-create";
    const parameters = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_GROUP_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const updategroupApi = (request) => {
    const UPDATE_GROUP_API = appApi + "" + role + "/group-update";
    const parameters = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request.formData)
    };

    return fetch(UPDATE_GROUP_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const createpodApi = (request) => {
    const CREATE_POD_API = appApi + "" + role + "/group/pod-create";
    const parameters = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_POD_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const updatepodApi = (request) => {
    const UPDATE_POD_API = appApi + "" + role + "/group/pod-update";
    const parameters = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request.formData)
    };

    return fetch(UPDATE_POD_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const addfacilitatorApi = (request) => {
    const ADD_FACILITATOR_API = appApi + "" + role + "/group-add-facilitator/" + request.formData.group_id;
    const parameters = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request.formData)
    };

    return fetch(ADD_FACILITATOR_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const removefacilitatorApi = (request) => {
    const REMOVE_FACILITATOR_API = appApi + "" + role + "/group-remove-facilitator/" + request.formData.group_id;
    const parameters = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request.formData)
    };

    return fetch(REMOVE_FACILITATOR_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const podinfoApi = (group_id, pod_id) => {
    const POD_INFO = appApi + "" + role + "/group/"+ group_id +"/pod-info/" + pod_id; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(POD_INFO, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const subscribegroupApi = (group_id) => {
    const SUBSCRIBE_GROUP_API = appApi + "" + role + "/group-subscribe/"+ group_id; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(SUBSCRIBE_GROUP_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const unsubscribegroupApi = (group_id) => {
    const UNSUBSCRIBE_GROUP_API = appApi + "" + role + "/group-unsubscribe/"+ group_id; 
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };
    
    return fetch(UNSUBSCRIBE_GROUP_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};





