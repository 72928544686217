import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Formik } from "formik";
import * as Yup from "yup";

const LoginForm = ({handleSubmit}) => (

    <Formik
        enableReinitialize
        initialValues={{  
            email: '',  
            password: ''
        }}
        onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
                setSubmitting(false);
                handleSubmit(values);
            }, 500);
        }}
        validationSchema={Yup.object().shape({
            email: Yup.string().email().required("Required"),
            password: Yup.string().required("No password provided.")
            .min(6, "Password is too short - should be 6 chars minimum.")
            .matches(/(?=.*[0-9])/, "Password must contain a number.")
        })}>
        {props => {
            const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm 
            } = props;

            return (
                <Form className="page-form" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <h4 className="page-title">Login</h4>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>Email Id</Form.Label>
                                <Form.Control 
                                type="email" 
                                name="email" 
                                value={values.email} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.email && !errors.email}
                                className={(errors.email && touched.email ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>   
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control 
                                type="password" 
                                name="password" 
                                value={values.password} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.password && !errors.password}
                                className={(errors.password && touched.password ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>                                
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12} className="text-end">
                            <Button type="submit" className="submit-link">Login</Button>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12} className="text-end">
                            <p className="vs">You don't have a account? <Link to={'/register'}><b>Register</b></Link></p>                            
                        </Col>
                    </Row>
                </Form>
            );
        }}
    </Formik>
);

export default LoginForm;
