export function authHeader() {

    let token = localStorage.getItem('token');
    if (token) {
        return {
            "Content-Type": 'application/jsonp',
            "Authorization": 'Bearer ' + token 
        };
    } else {
        return {};
    }
}