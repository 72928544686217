const components = {
	dashboard: {
		component: 'Dashboard',
		url: '/dashboard',
		title: 'Dashboard'
	},
	admin_list: {
		component: 'AdminList',
		url: '/admin-list',
		title: 'Admin List'
	},
	admin_create: {
		component: 'AdminCreate',
		url: '/admin-create',
		title: 'Admin Create'
	},
	admin_profile: {
		component: 'AdminProfile',
		url: '/admin-profile',
		title: 'Admin Profile'
	},
	facilitator_list: {
		component: 'FacilitatorList',
		url: '/facilitator-list',
		title: 'Facilitator List'
	},
	facilitator_create: {
		component: 'FacilitatorCreate',
		url: '/facilitator-create',
		title: 'Facilitator Create'
	},
	facilitator_edit: {
		component: 'FacilitatorEdit',
		url: '/facilitator-edit',
		title: 'Facilitator Edit'
	},
	facilitator_profile: {
		component: 'FacilitatorProfile',
		url: '/facilitator-profile/:facilitator_id',
		title: 'Facilitator Profile'
	},
	member_list: {
		component: 'MemberList',
		url: '/member-list',
		title: 'Member List'
	},
	member_create: {
		component: 'MemberCreate',
		url: '/member-create',
		title: 'Member Create'
	},
	member_edit: {
		component: 'MemberEdit',
		url: '/member-edit',
		title: 'Member Edit'
	},
	member_profile: {
		component: 'MemberProfile',
		url: '/member-profile/:member_id',
		title: 'Member Profile'
	},
	my_profile: {
		component: 'MyProfile',
		url: '/my-profile/:member_key',
		title: 'My Profile'
	},
	my_invoice: {
		component: 'MyInvoice',
		url: '/my-invoice/:invoice_key',
		title: 'My Invoice'
	},
	member_payment: {
		component: 'MemberPayment',
		url: '/member-payment/:member_key',
		title: 'Member Payment'
	},
	group_list: {
		component: 'GroupList',
		url: '/group-list',
		title: 'Group List'
	},
	group_create: {
		component: 'GroupCreate',
		url: '/group-create',
		title: 'Group Create'
	},
	group_info: {
		component: 'GroupInfo',
		url: '/group-info/:id',
		title: 'Group Info'
	},
	group_edit: {
		component: 'GroupEdit',
		url: '/group-edit/:group_id',
		title: 'Group Edit'
	},
	pod_create: {
		component: 'PodCreate',
		url: '/pod-create/:group_id',
		title: 'Pod Create'
	},
	pod_info: {
		component: 'PodInfo',
		url: '/pod-info/:group_id/:pod_id',
		title: 'Pod Info'
	},
	pod_edit: {
		component: 'PodEdit',
		url: '/pod-edit/:group_id/:pod_id',
		title: 'Pod Edit'
	},
	chat_box: {
		component: 'ChatBox',
		url: '/chat-box',
		title: 'Chat Box'
	}
};

const modules = {
	0: {
		title: 'Dashboard',
		icon: 'home',
		isExpendable: true
	}
};

const rolesConfig = {
	admin: {
		routes: [
			components.dashboard,
			components.admin_list,
			components.admin_create,
			components.admin_profile,
			components.facilitator_list,
			components.facilitator_create,
			components.facilitator_edit,
			components.facilitator_profile,
			components.member_list, 
			components.member_create, 
			components.member_edit, 
			components.member_profile, 
			components.group_list, 
			components.group_create, 
			components.group_info, 
			components.group_edit, 
			components.pod_create,
			components.pod_info,
			components.pod_edit,
			components.chat_box
		]
	},
	facilitator: {
		routes: [
			components.facilitator_profile,
			components.group_list,
			components.group_info,
			components.pod_create,
			components.pod_edit,
			components.pod_info
		]
	},
	member: {
		routes: [ 
			components.member_payment,
			components.my_profile,
			components.my_invoice,
			components.group_list,
			components.group_info,
			components.pod_info,
		]
	},
	common: {
		routes: [
			components.profile
		]
	}
};

export { modules, rolesConfig };