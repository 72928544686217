import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Icofont from 'react-icofont';
import { Scrollbars } from 'react-custom-scrollbars';
import { fetchFacilitators, toggleFacilitator, deleteFacilitator } from '../../actions';
import LoadingSpinner from '../partials/LoadingSpinner';

class FacilitatorList extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            facilitators:[],
            toggleData:[]
        };

        this.toggleHandler = this.toggleHandler.bind(this);
        this.filterHandler = this.filterHandler.bind(this);
    }
    componentDidMount() {
        this.props.dispatch(fetchFacilitators("name"));
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.data !== prevProps.data) {
            this.setState({
                facilitators:this.props.data,
            });
        }
        // if (this.props.toggledata !== prevProps.toggledata) {            
        // }
    }
    toggleHandler(facilitator_id, status){
        this.props.dispatch(toggleFacilitator(facilitator_id)); 

        let newstatus=status==="active"? "in_active" : "active";
        this.setState(prevState => ({
            facilitators: prevState.facilitators.map(
                el => el.id === facilitator_id? { ...el, status: newstatus }: el
            )
        }));

        toast.success("Facilitator status changed successfully!");
    }
    deleteHandler(facilitator_id){
        toast.error("Delete function currently Disabled!");
        // toast((t) => (
        //     <span>
        //         Confirm to <b>Delete</b> &nbsp;
        //         <Button variant="danger" size="sm" onClick={(event) => {
        //             toast.dismiss(t.id);
        //             this.props.dispatch(deleteFacilitator(facilitator_id));
        //             let filteredArray = this.state.facilitators.filter(item => item.id !== facilitator_id)
        //             this.setState({facilitators: filteredArray});
        //             toast.success("Facilitator Deleted!");
        //             }}>
        //             Confirm
        //         </Button>
        //     </span>
        // ));
    }
    filterHandler(e){
        this.props.dispatch(fetchFacilitators(e.target.value));
    }
    render() {
        const { loading } = this.props;
        const { authRole, facilitators } = this.state;

        const FacilitatorTable = () => {
            return(
                <div className="table-responsive">                              
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Facilitator Since</th>
                                <th>Approval Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {facilitators && facilitators.length > 0 ? facilitators.map(facilitator => (
                                <tr key={facilitator.id}>
                                    <td data-label="">
                                        <div className="flex-avator">
                                            <img src={facilitator.avator} alt=""/>
                                        </div>
                                    </td>
                                    <td data-label="Name">{facilitator.name}</td>
                                    <td data-label="Email"><Link className="line-link" to={'/' + authRole + '/facilitator-profile/' +facilitator.id}>{facilitator.email}</Link></td>
                                    <td data-label="Member Since">{facilitator.created_at}</td>
                                    <td data-label="Approval Status"><span onClick={() => this.toggleHandler(facilitator.id, facilitator.status)} className={facilitator.status} >{(facilitator.status==='active')? 'Active' : 'Inactive'}</span></td>
                                    <td data-label="Approval Status"><span onClick={() => this.deleteHandler(facilitator.id)} className="del-icon"><Icofont icon="ui-delete" /></span></td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="6">
                                        Empty Facilitators ...
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        }
            
        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <Link to={'/' + authRole + '/dashboard'}><span className="inner-lefticon"><Icofont icon="thin-left" /></span> Home</Link>
                </Col>
                <Col sm={{ span: 10, offset: 1 }}>
                    <Row>
                        <Col sm={9} className="inner-scroll">
                            <Row className="mb-2 align-items-center pr-4">
                                <Col sm={8} className="table-title">Facilitators</Col>
                                <Col sm={2} className="text-end font-xs">Sort By</Col>
                                <Col sm={2} className="whitu">
                                    <Form.Select size="sm" onChange={this.filterHandler}>
                                        <option value="name">Name</option>
                                        <option value="email">Email</option>
                                        <option value="status">Status</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Scrollbars style={{ width: '100%', height: '90%' }}
                            autoHide={false}
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            thumbMinSize={20}
                            thumbSize={20}
                            hideTracksWhenNotNeeded={false}>                                  
                                {loading ? <LoadingSpinner /> : <FacilitatorTable/>} 
                            </Scrollbars>
                        </Col>
                        <Col sm={3}>
                            <div className="inner-box">
                                <ul>
                                    <div>Facilitators</div>
                                    <li>Total</li>
                                    <li>Approved</li>
                                    <li>Un Approved</li>
                                </ul>
                                <Link className="new-link" to={'/' + authRole + '/facilitator-create'}>Add Facilitator</Link>
                            </div>
                        </Col>
                    </Row>
                </Col>   
                <Toaster />                                    
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.facilitattors.loading,     
        data: state.facilitattors.data.data,    
        toggledata: state.facilitattors.toggledata    
    }
}

export default connect(mapStateToProps)(FacilitatorList); 

