import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Icofont from 'react-icofont';
import PodForm from './PodForm';
import { createPod } from '../../actions';
import HomeLink from '../partials/HomeLink';

class PodCreate extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            group_id:this.props.match.params.group_id,
        };

        this.podformHandler = this.podformHandler.bind(this);
    }
    podformHandler(formData){
        Object.assign(formData, {group_id: this.state.group_id});
        this.props.dispatch(createPod(formData));
    }
    render() {

        const { history, toast_key } = this.props;
        const { authRole, group_id } = this.state;

        if(toast_key==='pod_created'){
            toast.success('Pod created successfully!');
            history.push('/' + authRole + '/group-info/' + group_id);
        }

        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <HomeLink role={authRole}/>
                </Col>
                <PodForm podformHandler={this.podformHandler} data={null}/>                              
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {        
        toast_key: state.groups.toast_key    
    }
}

export default connect(mapStateToProps)(PodCreate);  
