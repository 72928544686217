import React, {useState, useRef} from "react";
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Formik } from "formik";
import * as Yup from "yup";
import axios from"axios";
import { appApi } from '../../helpers';
import LoadingSpinner from '../partials/LoadingSpinner';

function FacilitatorForm({facilitatorformHandler, data, history}){

    const [preview, setPreview] = useState("");   

    return (
        <Formik
            enableReinitialize
            initialValues={{ 
                facilitator_id: data?data.id : '', 
                firstname: data?data.firstname : '', 
                lastname: data?data.lastname : '', 
                email: data?data.email : '', 
                phone: data?data.phone : '', 
                password: data?data.password : '', 
                linkedin_profile: data?data.linkedin_profile : '', 
                file:'',
                docs:''
            }}
            onSubmit={(values, { setErrors, setSubmitting }) => {
                let token = localStorage.getItem('token');
                setTimeout(() => {
                    (async () => {
                        try {
                            const result = await axios.post(appApi + "facilitator/facilitator-update", values, {
                                headers: {
                                    'Authorization': `Bearer ${token}`
                                },
                            });
                            history.push('/register-success');                       
                        } catch (exception) {
                            // Expected: 400 status code
                            if (exception.response && exception.response.status === 400) {
                                let form_errors=exception.response.data.errors;
                                setErrors(form_errors);
                                setSubmitting(false);
                            }
                        }
                    })();
                }, 500);
            }}
            >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue
                } = props;

                return (
                    <Col sm={{ span: 6, offset: 3 }}>
                        <Form className="page-form" onSubmit={handleSubmit}>
                            <Row className="mb-3 align-items-center">
                                <Col sm={2}>
                                    <Form.Group className="custom-document">                                
                                        <Form.Control type="file" id="file" name="file" onChange={(event) => {
                                            const fileReader = new FileReader();
                                            fileReader.onload = () => {
                                                if (fileReader.readyState === 2) {    
                                                    setPreview(fileReader.result);
                                                }
                                            };
                                            fileReader.readAsDataURL(event.target.files[0]);

                                            setFieldValue("avator", event.currentTarget.files[0]);
                                        }}/>
                                        { preview!==""? (
                                            <img className="custom-preview" alt="" src={preview}/> 
                                            ) : (
                                            <img className="custom-preview" alt="" src={data?data.avator : null }/> 
                                        )} 
                                        <Form.Control.Feedback type="invalid">{errors.file}</Form.Control.Feedback>                   
                                    </Form.Group>
                                </Col>
                                <Col sm={10}>
                                    <h4 className="page-title">Click to change your image</h4>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="page-title">Apply for facilitator</h4>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="firstname" 
                                            id="firstname" 
                                            value={values.firstname} 
                                            onChange={handleChange} 
                                            onBlur={handleBlur}
                                            isValid={touched.firstname && !errors.firstname}
                                            className={(errors.firstname && touched.firstname ? 'is-invalid' : '')}/>
                                            <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>                                   
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        name="lastname" 
                                        value={values.lastname} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.lastname && !errors.lastname}
                                        className={(errors.lastname && touched.lastname ? 'is-invalid' : '')}/>
                                        <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Email Id</Form.Label>
                                        <Form.Control 
                                        type="email" 
                                        name="email" 
                                        value={values.email} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.email && !errors.email}
                                        className={(errors.email && touched.email ? 'is-invalid' : '')}/>
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        name="phone" 
                                        value={values.phone} 
                                        onChange={handleChange}
                                        onBlur={handleBlur} 
                                        isValid={touched.phone && !errors.phone}
                                        className={(errors.phone && touched.phone ? 'is-invalid' : '')}/>
                                        <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={12}>
                                    <Form.Group>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control 
                                        // disabled={data? true : false}
                                        // placeholder={data? "Disabled" : ""}
                                        type="password" 
                                        name="password" 
                                        value={values.password} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.password && !errors.password}
                                        className={(errors.password && touched.password ? 'is-invalid' : '')}/>
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={12}>
                                    <Form.Group>
                                        <Form.Label>LinkedIn Profile</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        name="linkedin_profile" 
                                        value={values.linkedin_profile} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.linkedin_profile && !errors.linkedin_profile}
                                        className={(errors.linkedin_profile && touched.linkedin_profile ? 'is-invalid' : '')}/>
                                        <Form.Control.Feedback type="invalid">{errors.linkedin_profile}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={12}>
                                    <Form.Group>    
                                        <Form.Label>Upload Documents</Form.Label>                                                           
                                    </Form.Group>
                                    <Form.Group className="custom-document">                               
                                        <Form.Control type="file" id="document" name="document" multiple onChange={(event) => {
                                            const fileReader = new FileReader();
                                            fileReader.onload = () => {
                                                if (fileReader.readyState === 2) {    
                                                    setPreview(fileReader.result);
                                                }
                                            };
                                            fileReader.readAsDataURL(event.target.files[0]);

                                            setFieldValue("documents", event.currentTarget.files[0]);
                                        }}/>
                                        <img className="custom-preview" alt=""/>                                
                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col sm={12} className="text-end">
                                    <Button type="submit" className="submit-link">Update</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                );
            }}
        </Formik>
    )
}

export default FacilitatorForm;
