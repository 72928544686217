import React, { Component } from 'react';
import { connect } from 'react-redux';
import { appVerify } from '../../actions';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Icofont from 'react-icofont';
import LoadingSpinner from '../partials/LoadingSpinner';

class NotFound extends Component {
    render() {
        return (
            <Col sm={{ span: 6, offset: 3 }}>
                <div className="msg-info">                    
                    <h3>Not Found <span><Icofont icon="bug" /></span></h3>
                    <p>We're Sorry the page you requested could not be found. Please go back to the homepage.</p>
                    <Link to={'/login'}><Icofont icon="long-arrow-left" /> Home</Link>
                </div>                                
            </Col>                                 
        )
    }
}

export default NotFound; 
