import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Icofont from 'react-icofont';
import PodForm from './PodForm';
import toast from 'react-hot-toast';
import { podInfo, updatePod } from '../../actions';
import LoadingSpinner from '../partials/LoadingSpinner';
import HomeLink from '../partials/HomeLink';

class PodCreate extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            group_id:this.props.match.params.group_id,
            pod_id:this.props.match.params.pod_id,
        };

        this.podformHandler = this.podformHandler.bind(this);
    }
    componentDidMount() {
        this.props.dispatch(podInfo(this.state.group_id, this.state.pod_id));
    }
    podformHandler(formData){
        Object.assign(formData, {pod_id: this.state.pod_id});
        this.props.dispatch(updatePod(formData));
    }
    render() {

        const { authRole, group_id } = this.state;
        const { loading, data, toast_key, history } = this.props;

        if(toast_key==='pod_updated'){
            toast.success('Pod updated successfully!');
            history.push('/' + authRole + '/group-info/' + group_id);
        }

        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <HomeLink role={authRole}/>
                </Col>
                {loading ? <LoadingSpinner /> : <PodForm podformHandler={this.podformHandler} data={data}/>}                                     
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {        
        loading: state.groups.loading,    
        data: state.groups.data,    
        toast_key: state.groups.toast_key    
    }
}

export default connect(mapStateToProps)(PodCreate);  
