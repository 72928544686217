export const facilitatorConstants = {
    FACILITATORS: 'ALL_FACILITATORS',
    FACILITATORS_SUCCESS: 'ALL_FACILITATORS_SUCCESS',
    FACILITATORS_FAILURE: 'ALL_FACILITATORS_FAILURE',

    CREATE_FACILITATOR: 'CREATE_NEWFACILITATOR',
    CREATE_FACILITATOR_SUCCESS: 'CREATE_NEWFACILITATOR_SUCCESS',
    CREATE_FACILITATOR_FAILURE: 'CREATE_NEWFACILITATOR_FAILURE',

    TOGGLE_FACILITATOR: 'TOGGLE_STATUSFACILITATOR',
    TOGGLE_FACILITATOR_SUCCESS: 'TOGGLE_STATUSFACILITATOR_SUCCESS',
    TOGGLE_FACILITATOR_FAILURE: 'TOGGLE_STATUSFACILITATOR_FAILURE',   

    PROFILE_FACILITATOR: 'PROFILE_FACILITATOR',
    PROFILE_FACILITATOR_SUCCESS: 'PROFILE_FACILITATOR_SUCCESS',
    PROFILE_FACILITATOR_FAILURE: 'PROFILE_FACILITATOR_FAILURE',

    UPDATE_FACILITATOR: 'UPDATE_FACILITATOR',
    UPDATE_FACILITATOR_SUCCESS: 'UPDATE_FACILITATOR_SUCCESS',
    UPDATE_FACILITATOR_FAILURE: 'UPDATE_FACILITATOR_FAILURE',

    DELETE_FACILITATOR: 'DELETE_FACILITATOR',
    DELETE_FACILITATOR_SUCCESS: 'DELETE_FACILITATOR_SUCCESS',
    DELETE_FACILITATOR_FAILURE: 'DELETE_FACILITATOR_FAILURE',
};