import {put,call,takeLatest} from 'redux-saga/effects'
import { 
    allmemberApi, 
    createMemberApi, 
    togglememberApi, 
    profilememberApi,
    updatememberApi,
    myprofileApi,
    connectstripeApi,
    mymembershipApi,
    createmembershipApi,
    cancelmembershipApi,
    deletememberApi,
    memberinvoiceApi,
    myprofileupdateApi 
} from '../../services/memberServices';
import { memberConstants } from '../constants';

export function* allmemberSaga(payload) {
    try {
        const response = yield call(allmemberApi, payload);
        yield put({ type: memberConstants.MEMBERS_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.MEMBERS_FAILURE, error })
    }
}
export function* creatememberSaga(payload) {
    try {
        const response = yield call(createMemberApi, payload);
        yield put({ type: memberConstants.CREATE_MEMBER_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.CREATE_MEMBER_FAILURE, error })
    }
}
export function* togglememberSaga(payload) {
    try {
        const response = yield call(togglememberApi, payload.member_id);
        yield put({ type: memberConstants.TOGGLE_MEMBER_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.TOGGLE_MEMBER_FAILURE, error })
    }
}
export function* profilememberSaga(payload) {
    try {
        const response = yield call(profilememberApi, payload.member_id);
        yield put({ type: memberConstants.PROFILE_MEMBER_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.PROFILE_MEMBER_FAILURE, error })
    }
}
export function* myprofileSaga(payload) {
    try {
        const response = yield call(myprofileApi, payload.member_key);
        yield put({ type: memberConstants.MY_PROFILE_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.MY_PROFILE_FAILURE, error })
    }
}
export function* updatememberSaga(payload) {
    try {
        const response = yield call(updatememberApi, payload);
        yield put({ type: memberConstants.UPDATE_MEMBER_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.UPDATE_MEMBER_FAILURE, error })
    }
}
export function* updatemyprofileSaga(payload) {
    try {
        const response = yield call(myprofileupdateApi, payload);
        yield put({ type: memberConstants.UPDATE_MYPROFILE_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.UPDATE_MYPROFILE_FAILURE, error })
    }
}
export function* connectstripeSaga(payload) {
    try {
        const response = yield call(connectstripeApi, payload);
        yield put({ type: memberConstants.STRIPE_MEMBER_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.STRIPE_MEMBER_FAILURE, error })
    }
}
export function* mymembershipSaga(payload) {
    try {
        const response = yield call(mymembershipApi, payload.member_key);
        yield put({ type: memberConstants.MEMBERSHIP_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.MEMBERSHIP_FAILURE, error })
    }
}
export function* createmembershipSaga(payload) {
    try {
        const response = yield call(createmembershipApi, payload.member_key);
        yield put({ type: memberConstants.CREATE_MEMBERSHIP_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.CREATE_MEMBERSHIP_FAILURE, error })
    }
}
export function* cancelmembershipSaga(payload) {
    try {
        const response = yield call(cancelmembershipApi, payload.member_key);
        yield put({ type: memberConstants.CANCEL_MEMBERSHIP_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.CANCEL_MEMBERSHIP_FAILURE, error })
    }
}
export function* deletememberSaga(payload) {
    try {
        const response = yield call(deletememberApi, payload.member_id);
        yield put({ type: memberConstants.DELETE_MEMBER_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.DELETE_MEMBER_FAILURE, error })
    }
}
export function* memberinvoiceSaga(payload) {
    try {
        const response = yield call(memberinvoiceApi, payload.invoice_key);
        yield put({ type: memberConstants.GET_INVOICE_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: memberConstants.GET_INVOICE_FAILURE, error })
    }
}
export default function* adminSaga() {
    yield takeLatest(memberConstants.MEMBERS, allmemberSaga);
    yield takeLatest(memberConstants.CREATE_MEMBER, creatememberSaga);
    yield takeLatest(memberConstants.TOGGLE_MEMBER, togglememberSaga);
    yield takeLatest(memberConstants.PROFILE_MEMBER, profilememberSaga);
    yield takeLatest(memberConstants.UPDATE_MEMBER, updatememberSaga);
    yield takeLatest(memberConstants.MY_PROFILE, myprofileSaga);
    yield takeLatest(memberConstants.CONNECT_STRIPE, connectstripeSaga);
    yield takeLatest(memberConstants.MY_MEMBERSHIP, mymembershipSaga);    
    yield takeLatest(memberConstants.CREATE_MEMBERSHIP, createmembershipSaga);
    yield takeLatest(memberConstants.CANCEL_MEMBERSHIP, cancelmembershipSaga);
    yield takeLatest(memberConstants.DELETE_MEMBER, deletememberSaga);
    yield takeLatest(memberConstants.GET_INVOICE, memberinvoiceSaga);
    yield takeLatest(memberConstants.UPDATE_MYPROFILE, updatemyprofileSaga);
}