import {put,call,takeLatest} from 'redux-saga/effects'
import { 
    allfacilitatorApi, 
    createFacilitatorApi, 
    togglefacilitatorApi, 
    profilefacilitatorApi,
    updateFacilitatorApi,
    deletefacilitatorApi
} from '../../services/facilitatorServices';
import { facilitatorConstants } from '../constants';

export function* allfacilitatorSaga(payload) {
    try {
        const response = yield call(allfacilitatorApi, payload);
        yield put({ type: facilitatorConstants.FACILITATORS_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: facilitatorConstants.FACILITATORS_FAILURE, error })
    }
}
export function* createfacilitatorSaga(payload) {

    try {
        const response = yield call(createFacilitatorApi, payload);
        yield put({ type: facilitatorConstants.CREATE_FACILITATOR_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: facilitatorConstants.CREATE_FACILITATOR_FAILURE, error })
    }
}
export function* togglefacilitatorSaga(payload) {
    try {
        const response = yield call(togglefacilitatorApi, payload.facilitator_id);
        yield put({ type: facilitatorConstants.TOGGLE_FACILITATOR_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: facilitatorConstants.TOGGLE_FACILITATOR_FAILURE, error })
    }
}
export function* profilefacilitatorSaga(payload) {
    try {
        const response = yield call(profilefacilitatorApi, payload.facilitator_id);
        yield put({ type: facilitatorConstants.PROFILE_FACILITATOR_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: facilitatorConstants.PROFILE_FACILITATOR_FAILURE, error })
    }
}
export function* updatefacilitatorSaga(payload) {
    try {
        const response = yield call(updateFacilitatorApi, payload);
        yield put({ type: facilitatorConstants.UPDATE_FACILITATOR_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: facilitatorConstants.UPDATE_FACILITATOR_FAILURE, error })
    }
}
export function* deletefacilitatorSaga(payload) {
    try {
        const response = yield call(deletefacilitatorApi, payload.facilitator_id);
        yield put({ type: facilitatorConstants.DELETE_FACILITATOR_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: facilitatorConstants.DELETE_FACILITATOR_FAILURE, error })
    }
}
export default function* facilitatorSaga() {
    yield takeLatest(facilitatorConstants.FACILITATORS, allfacilitatorSaga);
    yield takeLatest(facilitatorConstants.CREATE_FACILITATOR, createfacilitatorSaga);
    yield takeLatest(facilitatorConstants.TOGGLE_FACILITATOR, togglefacilitatorSaga);
    yield takeLatest(facilitatorConstants.PROFILE_FACILITATOR, profilefacilitatorSaga);
    yield takeLatest(facilitatorConstants.UPDATE_FACILITATOR, updatefacilitatorSaga);
    yield takeLatest(facilitatorConstants.DELETE_FACILITATOR, deletefacilitatorSaga);
}