import { authHeader, appApi } from '../helpers';

export const verifyApi = (member_key) => {
    const VERIFY_API_ENDPOINT = appApi + 'account-verify/' + member_key;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    };

    return fetch(VERIFY_API_ENDPOINT, parameters).then(response => {
        console.log(response);
        return response.json();
    }).then(json => {
        return json;
    });
};
export const registerApi = (request) => {
    const REGISTER_API_ENDPOINT = appApi + 'register';
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(REGISTER_API_ENDPOINT, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const loginApi = (request) => {
    const LOGIN_API_ENDPOINT = appApi + 'login';
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": 'application/jsonp',
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(LOGIN_API_ENDPOINT, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};
export const logoutApi = (request) => {

    const LOGOUT_API_ENDPOINT = appApi + 'logout';
    const parameters = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(LOGOUT_API_ENDPOINT, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
};


