import React, { Component } from 'react';
import "./ChatBox.css";
import { Row, Col } from "react-bootstrap";
class ChatBox extends Component {
    
    render() {
        return (
            <div className="messanger">
                <Row className="messanger-header">
                    <Col sm={12}>
                        <h4>Your Chats</h4>
                        <p>Chat with your clients</p>
                    </Col>
                </Row>
                <Row className="messanger-body">
                    <Col sm={5}>
                        <div className="ms-memmbers">
                            
                        </div>
                    </Col>
                    <Col sm={7}>
                        <div className="ms-chats">
                            <ul className="ms-chatlist">
                                <li>
                                    <div></div>
                                    <div>sdjsdsddmnki</div>
                                </li>
                                <li>
                                    <div></div>
                                    <div>sdjsdsddmnki</div>
                                </li>
                                <li>
                                    <div></div>
                                    <div>sdjsdsddmnki</div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        ) 
    }
}
  
export default ChatBox;
