import React, { Component } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

class YourWeek extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            user:JSON.parse(localStorage.getItem('user')),
        };
    }
    render() {
        const {your_pods} = this.props;
        const { user } = this.state;
        return (
            <div className="your-week">
                <div className="middle">
                    <div className="week-title">Your Week</div>
                    <div className="inner">
                        <ul>
                            {your_pods.map(yourpod => yourpod.pods.length !== 0 ? (
                                <OverlayTrigger
                                    key={yourpod.order}
                                    trigger="click"
                                    placement="left"
                                    rootClose
                                    overlay={
                                        <Popover id={`popover-positioned-left`}>
                                            <Popover.Body>
                                                <Carousel className="pop-carousal yourweak-pop" indicators={false} interval={null}>
                                                    {yourpod.pods.map((ev_pod) => (
                                                        <Carousel.Item key={ev_pod.id} className="pod-notify">
                                                            <h4 className="text-headColor">Your Sheduled Session</h4>
                                                            <h5 className="text-yellowColor">{ev_pod.topic}</h5>
                                                            <p className="text-yellowColor text-truncate">{ev_pod.description}</p>
                                                            <Image src={user.avator} roundedCircle />
                                                            <span className="text-yellowColor">{ev_pod.pod_owner.name}</span>
                                                        </Carousel.Item>
                                                    ))}
                                                </Carousel>
                                            </Popover.Body>
                                        </Popover>
                                    }>
                                        <li className={"weekday " + yourpod.className}>{yourpod.day} {yourpod.className==="today"? (<span className="is_yourday"></span>) : null}</li>
                                    </OverlayTrigger> 
                                ) : (
                                    <li key={yourpod.day} className={"weekday disabled " + yourpod.className}>{yourpod.day} { yourpod.className==="today"? (<span className="is_yourday"></span>) : null}</li>
                                )
                            )}
                        </ul>                            
                    </div>
                </div>
            </div>
        )
    }
}
  
export default YourWeek
