import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { rolesConfig } from './roles';
import * as Routes from './index';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Header from '../Layouts/Header';

class PrivateRoutes extends Component {
	constructor(props){
    	super(props);
   
    	this.state = {
    		authRole:'',
	     	allowedRoutes: [] 
    	};
  	}
	componentDidMount() {
		let userRole = localStorage.getItem('role');
		if (userRole) {
			let roleArray = ['common', userRole];
			let allocatedRoutes = roleArray.reduce((acc, role) => {
				return [...rolesConfig[role].routes];
			}, []);

			allocatedRoutes = uniqBy(allocatedRoutes, 'url');
		   	this.setState({
			  	allowedRoutes: [...this.state.allowedRoutes, allocatedRoutes],
			  	authRole: userRole,
			});
		} 
	}

	render() {
		const {pathname} = this.props.location;
		let page_bg="none f-height";
		const path_array = pathname.split("/");
		const page=path_array[2];

		if(page==="group-list"){
			page_bg="flower_bg";
		}
		else if(page==="group-info" || page==="pod-info"){
			page_bg="snow_bg";
		}

		return (
			<Fragment>				
				<Header />
				<div className={page_bg}>
					<Container className="full-height">
						<Switch>
							{ this.state && this.state.allowedRoutes && this.state.allowedRoutes[0] && this.state.allowedRoutes[0].map((route) => (														
								<Route 
									exact 
									key={route.url} 
									component={Routes[route.component]} 
									path={`${this.props.match.path}${route.url}`}
								/>
							))}
						</Switch>
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default PrivateRoutes;
