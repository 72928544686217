import { adminConstants } from '../constants';

const initialState = {
    loading: false, 
    data:[]
};

export default function adminReducer( state=initialState, {type, response}) {

    switch(type) {
        case adminConstants.ADMINS:
            return { 
                ...state,
                loading:true 
            };
        case adminConstants.ADMINS_SUCCESS:
            return { 
                ...state, 
                loading:false,                 
                data:response.data
            };
        case adminConstants.ADMINS_FAILURE:
            return { 
                ...state,
                loading:false 
            };

        default:
            return state;
    }
};
