import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { myProfile, updateMyprofile } from '../../actions';
import MyForm from './MyForm';
import LoadingSpinner from '../partials/LoadingSpinner';
import HomeLink from '../partials/HomeLink';

class MyProfile extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            member_key: this.props.match.params.member_key,
            invoices:[],
            avator:'',
        };

        this.avatorHandler = this.avatorHandler.bind(this);
        this.memberformHandler = this.memberformHandler.bind(this)
    }
    componentDidMount() {

        this.props.dispatch(myProfile(this.state.member_key));
    }
    avatorHandler(values){
        this.setState({
            avator:values
        });
    }
    memberformHandler(formData){
        Object.assign(formData, {member_key: this.state.member_key});
        this.props.dispatch(updateMyprofile(formData));
    }
    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState({
                invoices:nextProps.data.invoices
            });
        }
    }
    render() {
        const { authRole, member_key, invoices } = this.state;
        const { loading, data, toast_key, history } = this.props;

        if(toast_key==='profile_updated'){
            toast.success("Member updated successfully!");
            history.push('/' + authRole + '/member-list');
        }

        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <HomeLink role={authRole}/>
                </Col>                
                {loading ? <LoadingSpinner /> : (
                    <Col sm={{ span: 6, offset: 3 }} className="full-height">
                        <MyForm memberformHandler={this.memberformHandler} avatorHandler={this.avatorHandler} data={data} member_key={member_key} history={history}/>
                        <h4 className="page-title">Invoice</h4>
                        <Table size="sm invoice-table">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Date of paid</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {invoices && invoices.length > 0 ? invoices.map(inv => (
                                <tr key={inv.id}>
                                    <td>Premium Membership for Socialpods</td>
                                    <td>{inv.amount_due} $</td>
                                    <td>{inv.created}</td>
                                    <td><Link to={'/member/my-invoice/' + inv.id} className="subscribe-link">View Invoice</Link></td>
                                </tr>
                            )) : null}                                                            
                            </tbody>
                        </Table>
                    </Col>
                    )
                }                    
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {        
        data: state.members.data,    
        toast_key: state.members.toast_key,    
        loading: state.members.loading,    
    }
}

export default connect(mapStateToProps)(MyProfile);  
