import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';

class ChatBox extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
        };
    }
    componentDidMount() {
        
    }
    render() {
        const { authRole } = this.state;
        
        return (
            <div className="container full-height">
                <div className="row full-height align-items-center">
                    <div className="col text-center">
                        <h1>The Meeting Ended ....for everyone</h1>
                        <Link to="/google" className="back-link">Back</Link>
                    </div>
                </div>
            </div>                          
        )
    }
}

export default ChatBox

