import { authConstants } from '../constants';

const initialState = {
    loading: false, 
    toast_key:'',
    token:null,
    user:[],
    errors:[]
};

export default function authReducer( state=initialState, {type, response}) {

    switch(type) {
        case authConstants.VERIFY_REQUEST:
            return { 
                ...state,
                toast_key:'',
                loading:true 
            };
        case authConstants.VERIFY_SUCCESS:
            return { 
                ...state, 
                loading:false,                 
                toast_key:response.toast_key,
                user:response.data,
            };
        case authConstants.VERIFY_FAILURE:
            return { 
                ...state,
                toast_key:response.toast_key,
                loading:false 
            };

        case authConstants.REGISTER_REQUEST:
            return { 
                ...state,
                toast_key:'',
                loading:true 
            };
        case authConstants.REGISTER_SUCCESS:
        console.log(response.errors);
            return { 
                ...state, 
                loading:false,                 
                toast_key:response.toast_key,
                user:response.data,
                errors:response.errors,
            };
        case authConstants.REGISTER_FAILURE:        
            return { 
                ...state,
                toast_key:response.toast_key,
                error:response.error,
                loading:false 
            };

        case authConstants.LOGIN_REQUEST:
            return { 
                ...state,
                toast_key:'',
                loading:true 
            };
        case authConstants.LOGIN_SUCCESS:
            return { 
                ...state, 
                loading:false,                 
                toast_key:response.toast_key,
                token:response.token,
                user:response.user
            };
        case authConstants.LOGIN_FAILURE:
            return { 
                ...state,
                toast_key:response.toast_key,
                loading:false 
            };

        case authConstants.LOGOUT:
            return { 
                ...state,
                toast_key:'',
                loading:true 
            };

        case authConstants.LOGOUT_SUCCESS:
            return { 
                ...state,
                toast_key:response.toast_key,
                loading:false
            };  
        case authConstants.LOGOUT_FAILURE:
            return { 
                ...state,
                toast_key:response.toast_key,
                loading:false
            };          
        default:
            return state;
    }
};
