export const meetConstants = {
    CREATE_MEETING: 'CREATE_MEETING',
    CREATE_MEETING_SUCCESS: 'NEW_CREATE_MEETING_SUCCESS',
    CREATE_MEETING_FAILURE: 'NEW_CREATE_MEETING_FAILURE',

    JOIN_MEETING: 'JOIN_MEETING',
    JOIN_MEETING_SUCCESS: 'NEW_JOIN_MEETING_SUCCESS',
    JOIN_MEETING_FAILURE: 'NEW_JOIN_MEETING_FAILURE',

    END_MEETING: 'END_MEETING',
    END_MEETING_SUCCESS: 'NEW_END_MEETING_SUCCESS',
    END_MEETING_FAILURE: 'NEW_END_MEETING_FAILURE',

    LIVE_MEETING: 'LIVE_MEETING',
    LIVE_MEETING_SUCCESS: 'NEW_LIVE_MEETING_SUCCESS',
    LIVE_MEETING_FAILURE: 'NEW_LIVE_MEETING_FAILURE',
};