import { groupConstants } from '../constants';

const initialState = {
    toast_key: '',
    loading: false, 
    data:[],
    your_week:[],
    new_facilitator:[]
};

export default function groupReducer( state=initialState, {type, response}) {

    switch(type) {
        case groupConstants.GROUPS:
            return { 
                ...state,
                loading:true 
            };
        case groupConstants.GROUPS_SUCCESS:
            return { 
                ...state, 
                loading:false, 
                toast_key:'',                
                data:response.data,
                your_week:response.your_week
            };
        case groupConstants.GROUPS_FAILURE:
            return { 
                ...state,
                loading:false 
            };

        case groupConstants.GROUPINFO:
            return { 
                ...state,
                loading:true 
            };
        case groupConstants.GROUPINFO_SUCCESS:
            return { 
                ...state, 
                loading:false,   
                toast_key:'',              
                data:response.data
            };
        case groupConstants.GROUPINFO_FAILURE:
            return { 
                ...state,
                loading:false 
            };

        case groupConstants.CREATE_GROUP:
            return { 
                ...state,
                loading:true 
            };
        case groupConstants.CREATE_GROUP_SUCCESS:
            return { 
                ...state, 
                loading:false,
                toast_key:response.toast_key,                 
                data:response.data
            };
        case groupConstants.CREATE_GROUP_FAILURE:
            return { 
                ...state,
                loading:false 
            };

        case groupConstants.UPDATE_GROUP:
            return { 
                ...state,
                loading:true 
            };
        case groupConstants.UPDATE_GROUP_SUCCESS:
            return { 
                ...state, 
                loading:false,
                toast_key:response.toast_key
            };
        case groupConstants.UPDATE_GROUP_FAILURE:
            return { 
                ...state,
                loading:false 
            };

        case groupConstants.CREATE_POD:
            return { 
                ...state,
                loading:true 
            };
        case groupConstants.CREATE_POD_SUCCESS:
            return { 
                ...state, 
                loading:false,                 
                toast_key:response.toast_key,                   
                data:response.data
            };
        case groupConstants.CREATE_POD_FAILURE:
            return { 
                ...state,
                loading:false 
            };

        case groupConstants.ADD_FACILITATOR:
            return { 
                ...state,
                loading:false,
                toast_key:'' 
            };
        case groupConstants.ADD_FACILITATOR_SUCCESS:
            return { 
                ...state, 
                loading:false,                 
                toast_key:response.toast_key,
                group_facilitators:response.group_facilitators
            };
        case groupConstants.ADD_FACILITATOR_FAILURE:
            return { 
                ...state,
                loading:false,
                toast_key:''  
            };

        case groupConstants.REMOVE_FACILITATOR:
            return { 
                ...state,
                loading:false,
                toast_key:''  
            };
        case groupConstants.REMOVE_FACILITATOR_SUCCESS:
            return { 
                ...state, 
                loading:false,                 
                toast_key:response.toast_key
            };
        case groupConstants.REMOVE_FACILITATOR_FAILURE:
            return { 
                ...state,
                loading:false,
                toast_key:''  
            };

        case groupConstants.POD_INFO:
            return { 
                ...state,
                loading:true 
            };
        case groupConstants.POD_INFO_SUCCESS:
            return { 
                ...state, 
                loading:false,                 
                data:response.data
            };
        case groupConstants.POD_INFO_FAILURE:
            return { 
                ...state,
                loading:false 
            };    

        case groupConstants.UPDATE_POD:
            return { 
                ...state,
                loading:true 
            };
        case groupConstants.UPDATE_POD_SUCCESS:
            return { 
                ...state, 
                loading:false,
                toast_key:response.toast_key
            };
        case groupConstants.UPDATE_POD_FAILURE:
            return { 
                ...state,
                loading:false 
            };

        case groupConstants.SUBSCRIBE_GROUP:
            return { 
                ...state,
                loading:false 
            };
        case groupConstants.SUBSCRIBE_GROUP_SUCCESS:
            return { 
                ...state, 
                loading:false,
                toast_key:response.toast_key
            };
        case groupConstants.SUBSCRIBE_GROUP_FAILURE:
            return { 
                ...state,
                loading:false 
            };

        case groupConstants.UNSUBSCRIBE_GROUP:
            return { 
                ...state,
                loading:false 
            };
        case groupConstants.UNSUBSCRIBE_GROUP_SUCCESS:
            return { 
                ...state, 
                loading:false,
                toast_key:response.toast_key
            };
        case groupConstants.UNSUBSCRIBE_GROUP_FAILURE:
            return { 
                ...state,
                loading:false 
            };

        default:
            return state;
    }
};
