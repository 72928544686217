import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';
import toast from 'react-hot-toast';
import { fetchGroupinfo, updateGroup } from '../../actions';
import GroupForm from './GroupForm';

class GroupEdit extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            group_id:this.props.match.params.group_id,
        };

        this.groupformHandler = this.groupformHandler.bind(this)
    }
    componentDidMount() {
        this.props.dispatch(fetchGroupinfo(this.state.group_id));
    }
    groupformHandler(formData){
        Object.assign(formData, {group_id: this.state.group_id});
        this.props.dispatch(updateGroup(formData));
    }
    render() {

        const { authRole } = this.state;
        const { history, toast_key, data } = this.props;

        if(toast_key==='group_updated'){
            toast.success('Group updated successfully!');
            history.push('/' + authRole + '/group-list');
        }

        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <Link to={'/' + authRole + '/dashboard'}><span className="inner-lefticon"><Icofont icon="thin-left" /></span> Home</Link>
                </Col>
                <Col sm={{ span: 6, offset: 3 }}>
                    <GroupForm groupformHandler={this.groupformHandler} data={data}/>
                </Col>                                       
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {    
        toast_key: state.groups.toast_key,  
        data: state.groups.data,  
    }
}
  
export default connect(mapStateToProps)(GroupEdit);  

