import React from "react";
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import 'rc-time-picker/assets/index.css';
import { Formik, Field } from "formik";
import * as Yup from "yup";

const PodForm = ({podformHandler, data}) => (

    <Formik
        initialValues={{ 
            group_id:data?data.group_id : '',
            topic: data?data.topic : '', 
            type: data?data.type : '', 
            description: data?data.description : '',
            day: data?data.day : '',
            week: data?data.week : '',
            duration: data?data.duration : '',
            time: data?data.time : '',
            timezone: data?data.timezone : ''
        }}
        onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
                setSubmitting(false);
                podformHandler(values);
            }, 500);
        }}
        validationSchema={Yup.object().shape({
            topic: Yup.string().required("Required"),
            type: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
            day: Yup.string().required("Required"),
            week: Yup.string().required("Required"),
            duration: Yup.string().required("Required"),
            time: Yup.string().required("Required"),
            timezone: Yup.string().required("Required")
        })}>
        {props => {
            const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit
            } = props;

            console.log(data);

            return (
                <Col sm={{ span: 6, offset: 3 }}>
                <Form className="page-form" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <h4 className="page-title">Create new pod</h4>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={9}>
                                <Form.Group>
                                    <Form.Label>Topic</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="topic" 
                                        id="topic" 
                                        value={values.topic} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.topic && !errors.topic}
                                        classtopic={(errors.topic && touched.topic ? 'is-invalid' : '')}/>                                   
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Type Of Session</Form.Label>
                                    <Form.Select 
                                        type="text" 
                                        name="type" 
                                        defaultValue={values.type} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.type && !errors.type}
                                        className={(errors.type && touched.type ? 'is-invalid' : '')}>
                                        <option>Choose...</option>
                                        <option value="weekly">Weekly</option>
                                        <option value="daily">Daily</option>
                                    </Form.Select>                                
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={10} 
                                        type="text" 
                                        name="description" 
                                        id="description" 
                                        value={values.description} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.description && !errors.description}
                                        className={(errors.description && touched.description ? 'is-invalid' : '')}/>                                   
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Set Day in the week</Form.Label>
                                    <Form.Select
                                        name="day" 
                                        defaultValue={values.day} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.day && !errors.day}
                                        className={(errors.day && touched.day ? 'is-invalid' : '')}>
                                        <option>Choose...</option>
                                        <option value="sunday">Sunday</option>
                                        <option value="monday">Monday</option>
                                        <option value="tuesday">Tuesday</option>
                                        <option value="wednesday">Wednesday</option>
                                        <option value="thursday">Thursday</option>
                                        <option value="friday">Friday</option>
                                        <option value="saturday">Saturday</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>No of weeks</Form.Label>
                                    <Form.Select  
                                        name="week" 
                                        defaultValue={values.week} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.week && !errors.week}
                                        className={(errors.week && touched.week ? 'is-invalid' : '')}>
                                        <option>Choose...</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Duration</Form.Label>
                                    <Form.Select 
                                        name="duration" 
                                        defaultValue={values.duration} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.duration && !errors.duration}
                                        className={(errors.duration && touched.duration ? 'is-invalid' : '')}>
                                        <option>Choose...</option>
                                        <option value="00:30:00">00:30</option>
                                        <option value="01:00:00">01:00</option>
                                        <option value="01:30:00">01:30</option>
                                        <option value="02:00:00">02:00</option>
                                        <option value="02:30:00">02:30</option>
                                        <option value="03:00:00">03:00</option>
                                        <option value="03:30:00">03:30</option>
                                        <option value="04:00:00">04:00</option>
                                        <option value="04:30:00">04:30</option>
                                        <option value="05:00:00">05:00</option>
                                        <option value="05:30:00">05:30</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Set Time</Form.Label>
                                        <Form.Select  
                                        name="time" 
                                        defaultValue={values.time} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.time && !errors.time}
                                        className={(errors.time && touched.time ? 'is-invalid' : '')}>
                                        <option>Choose...</option>
                                        <option value="00:00">00:00</option>
                                        <option value="00:15">00:15</option>
                                        <option value="00:30">00:30</option>
                                        <option value="00:45">00:45</option>
                                        <option value="01:00">01:00</option>
                                        <option value="01:15">01:15</option>
                                        <option value="01:30">01:30</option>
                                        <option value="01:45">01:45</option>
                                        <option value="02:00">02:00</option>
                                        <option value="02:15">02:15</option>
                                        <option value="02:30">02:30</option>
                                        <option value="02:45">02:45</option>
                                        <option value="03:00">03:00</option>
                                        <option value="03:15">03:15</option>
                                        <option value="03:30">03:30</option>
                                        <option value="03:45">03:45</option>
                                        <option value="04:00">04:00</option>
                                        <option value="04:15">04:15</option>
                                        <option value="04:30">04:30</option>
                                        <option value="04:45">04:45</option>
                                        <option value="05:00">05:00</option>
                                        <option value="05:15">05:15</option>
                                        <option value="05:30">05:30</option>
                                        <option value="05:45">05:45</option>
                                        <option value="06:00">06:00</option>
                                        <option value="06:15">06:15</option>
                                        <option value="06:30">06:30</option>
                                        <option value="06:45">06:45</option>

                                        <option value="07:00">07:00</option>
                                        <option value="07:15">07:15</option>
                                        <option value="07:30">07:30</option>
                                        <option value="07:45">07:45</option>
                                        <option value="08:00">08:00</option>
                                        <option value="08:15">08:15</option>
                                        <option value="08:30">08:30</option>
                                        <option value="08:45">08:45</option>
                                        <option value="09:00">09:00</option>
                                        <option value="09:15">09:15</option>
                                        <option value="09:30">09:30</option>
                                        <option value="09:45">09:45</option>
                                        <option value="10:00">10:00</option>
                                        <option value="10:15">10:15</option>
                                        <option value="10:30">10:30</option>
                                        <option value="10:45">10:45</option>
                                        <option value="11:00">11:00</option>
                                        <option value="11:15">11:15</option>
                                        <option value="11:30">11:30</option>
                                        <option value="11:45">11:45</option>
                                        <option value="12:00">12:00</option>
                                        <option value="12:15">12:15</option>
                                        <option value="12:30">12:30</option>
                                        <option value="12:45">12:45</option>

                                        <option value="13:00">13:00</option>
                                        <option value="13:15">13:15</option>
                                        <option value="13:30">13:30</option>
                                        <option value="13:45">13:45</option>
                                        <option value="14:00">14:00</option>
                                        <option value="14:15">14:15</option>
                                        <option value="14:30">14:30</option>
                                        <option value="14:45">14:45</option>
                                        <option value="15:00">15:00</option>
                                        <option value="15:15">15:15</option>
                                        <option value="15:30">15:30</option>
                                        <option value="15:45">15:45</option>
                                        <option value="16:00">16:00</option>
                                        <option value="16:15">16:15</option>
                                        <option value="16:30">16:30</option>
                                        <option value="16:45">16:45</option>
                                        <option value="17:00">17:00</option>
                                        <option value="17:15">17:15</option>
                                        <option value="17:30">17:30</option>
                                        <option value="17:45">17:45</option>
                                        <option value="18:00">18:00</option>
                                        <option value="18:15">18:15</option>
                                        <option value="18:30">18:30</option>
                                        <option value="18:45">18:45</option>

                                        <option value="19:00">19:00</option>
                                        <option value="19:15">19:15</option>
                                        <option value="19:30">19:30</option>
                                        <option value="19:45">19:45</option>
                                        <option value="20:00">20:00</option>
                                        <option value="20:15">20:15</option>
                                        <option value="20:30">20:30</option>
                                        <option value="20:45">20:45</option>
                                        <option value="21:00">21:00</option>
                                        <option value="21:15">21:15</option>
                                        <option value="21:30">21:30</option>
                                        <option value="21:45">21:45</option>
                                        <option value="22:00">22:00</option>
                                        <option value="22:15">22:15</option>
                                        <option value="22:30">22:30</option>
                                        <option value="22:45">22:45</option>
                                        <option value="23:00">23:00</option>
                                        <option value="23:15">23:15</option>
                                        <option value="23:30">23:30</option>
                                        <option value="23:45">23:45</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={8}>
                                <Form.Group>
                                    <Form.Label>Set Time zone</Form.Label>
                                    <Form.Select  
                                        name="timezone" 
                                        defaultValue={values.timezone} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.timezone && !errors.timezone}
                                        className={(errors.timezone && touched.timezone ? 'is-invalid' : '')}>
                                        <option>Choose...</option>
                                        <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
                                        <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                                        <option value="-10:00">(GMT -10:00) Hawaii</option>
                                        <option value="-09:50">(GMT -9:30) Taiohae</option>
                                        <option value="-09:00">(GMT -9:00) Alaska</option>
                                        <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                                        <option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                                        <option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                                        <option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                                        <option value="-04:50">(GMT -4:30) Caracas</option>
                                        <option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                                        <option value="-03:50">(GMT -3:30) Newfoundland</option>
                                        <option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                                        <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                                        <option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
                                        <option value="+00:00">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                                        <option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                                        <option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
                                        <option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                                        <option value="+03:50">(GMT +3:30) Tehran</option>
                                        <option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                                        <option value="+04:50">(GMT +4:30) Kabul</option>
                                        <option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                                        <option value="+05:50">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                                        <option value="+05:75">(GMT +5:45) Kathmandu, Pokhara</option>
                                        <option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                                        <option value="+06:50">(GMT +6:30) Yangon, Mandalay</option>
                                        <option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                                        <option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                                        <option value="+08:75">(GMT +8:45) Eucla</option>
                                        <option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                                        <option value="+09:50">(GMT +9:30) Adelaide, Darwin</option>
                                        <option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                                        <option value="+10:50">(GMT +10:30) Lord Howe Island</option>
                                        <option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                                        <option value="+11:50">(GMT +11:30) Norfolk Island</option>
                                        <option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                        <option value="+12:75">(GMT +12:45) Chatham Islands</option>
                                        <option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
                                        <option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col sm={12} className="text-end">
                                <Button type="submit" className="submit-link">Save</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>    
            );
        }}
    </Formik>
);

export default PodForm;
