import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { appLogin } from '../../actions';
import toast, {Toaster} from 'react-hot-toast';
import './style.css';
import Logo from '../../Sociallogo.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginForm from './LoginForm';
import LoadingSpinner from '../partials/LoadingSpinner';
import {history} from '../../helpers';

class Login extends Component {
    constructor(props){
        super(props);
   
        this.state = {
            email: '',
            password: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    handleSubmit(formData) {
        this.props.dispatch(appLogin(formData));
    }
    render() {
        const { loading, toast_key, token, user } = this.props;

        if (loading) return <LoadingSpinner />;

        if (toast_key==='user_loggedin') {
            localStorage.setItem('role', user.role);         
            localStorage.setItem('token', token);         
            localStorage.setItem('user', JSON.stringify(user));

            if(user.role==="admin"){
                //this.props.history.replace('/admin/dashboard');
                //this.props.history.replace({ pathname: '/admin/dashboard', state:{isActive: true}});
                window.location = '/'+ user.role.toLowerCase() +'/dashboard';
            }
            else if(user.role==="facilitator"){
                //this.props.history.replace('/facilitator/group-list');
                this.props.history.replace({ pathname: '/facilitator/group-list', state:{isActive: true}});
                window.location = '/'+ user.role.toLowerCase() +'/group-list';
            }
            else if(user.role==="member"){
                //this.props.history.replace('/member/group-list');
                this.props.history.replace({ pathname: '/facilitator/group-list', state:{isActive: true}});
                window.location = '/'+ user.role.toLowerCase() +'/group-list';
            }            
        }
        else if(toast_key==='user_not_activated'){
            toast.error("You are not activated yet! Sorry");
        }
        else if(toast_key==='login_invalid'){
            toast.error("Your credentials is wrong!");
        }
        else if(toast_key==='user_disabled'){
            toast.error("Your account has been locked! Please contact administrator.");
        }

        
                
        return (   
            <Row>
                <Col sm={{ span: 4, offset: 4 }}>
                    <LoginForm handleSubmit={this.handleSubmit} />          
                    <Toaster position="bottom-center" reverseOrder={false}/>         
                </Col>                
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        toast_key: state.auth.toast_key,
        token: state.auth.token,
        user: state.auth.user      
    }
}
 
export default connect(mapStateToProps)(Login); 

