import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { connectStripe, myMembership, createMembership, cancelMembership } from '../../actions';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Icofont from 'react-icofont';
import CheckoutForm from './CheckoutForm';
import LoadingSpinner from '../partials/LoadingSpinner';
import HomeLink from '../partials/HomeLink';
const promise = loadStripe("pk_test_51JftmaC9yTY3d8Il1H9nGoIpES2wi6LiAkUxS3tvigBLaB0dVV9Od9oPgImJRLqwSIRDqww0KptPkPabiWuacmKk00NPC3yrUp");


class MemberPayment extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            member_key:this.props.match.params.member_key
        };

        this.helloStripe = this.helloStripe.bind(this);
        this.createSubscription = this.createSubscription.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
    }
    componentDidMount() {
        this.props.dispatch(myMembership(this.state.member_key));
    }
    helloStripe(token){
        const formData = {
            token:token,
            member_key:this.state.member_key
        };
        this.props.dispatch(connectStripe(formData));
    }
    createSubscription(e){
        e.preventDefault();
        this.props.dispatch(createMembership(this.state.member_key));
    }
    cancelSubscription(e){
        e.preventDefault();
        this.props.dispatch(cancelMembership(this.state.member_key));
    }
    render() {
        const { loading, membership } = this.props;
        const { authRole } =this.state;

        return (
            <Fragment>
                {loading ? <LoadingSpinner /> : (
                    <Fragment>
                        <Col sm={12} className="home-title">
                            <HomeLink role={authRole}/>
                        </Col>  
                        <Col sm={{ span: 4, offset: 4 }}>

                            { membership && membership.membership_type==='free'? (
                                <Elements stripe={promise}>
                                    <CheckoutForm helloStripe={this.helloStripe}/>
                                    <Alert variant="danger msg">
                                        <p>Once you have added the card number, You are allowed us 1 week trial user. You will be automatically charged $25 on every week!</p>
                                    </Alert>                                      
                                </Elements>

                            ) : null}

                            { membership && membership.membership_type==='trail'? (
                                <div className="msg-info msg-info-alt"> 
                                    <center>                     
                                        <h2 style={{margin: "20px"}}>Subscribed <span style={{color: "green", fontSize: "40px"}}><Icofont icon="credit-card" /></span></h2>
                                        <p>Subscribed, On weekly basis subscription! with $25 per Week. & trial period ends on {membership.next_payment_date}! once trail period ends amount will be taken on every week!</p>
                                        <Button variant="danger mt-4" size="sm" onClick={this.cancelSubscription}>Cancel Subscription</Button>
                                    </center>
                                </div>
                            ) : null}

                            { membership && membership.membership_type==='paymentmethod_added'? (
                                <div className="msg-info msg-info-alt"> 
                                    <center>                     
                                        <h2 style={{margin: "20px"}}>Payment Method Added <span style={{color: "green", fontSize: "40px"}}><Icofont icon="credit-card" /></span></h2>
                                        <p>Your selected payment method has been added to Stripe Account. Now you can subscribe to our Pods!</p>
                                    </center>
                                </div>
                            ) : null}

                            { membership && membership.membership_type==='subscribed'? (
                                <div className="msg-info msg-info-alt">      
                                    <center>              
                                        <h2 style={{margin: "20px"}}>Subscribed <span style={{color: "green", fontSize: "40px"}}><Icofont icon="check-circled" /></span></h2>
                                        <p>You already subscribed on weekly basis subscription! with $25 per Week. You can able cancel anytime</p>
                                        <Button variant="danger mt-4" size="sm" onClick={this.cancelSubscription}>Cancel Subscription</Button>
                                    </center>
                                </div>
                            ) : null}

                            { membership && membership.membership_type==='cancelled'? (
                                <div className="msg-info msg-info-alt">   
                                    <center>                    
                                        <h2 style={{margin: "20px"}}>Cancelled <span style={{color: "green", fontSize: "40px"}}><Icofont icon="close-circled" /></span></h2>
                                        <p>Your subscription has been cancelled!</p>
                                        <Button variant="success mt-4" size="sm" onClick={this.createSubscription}>Enable Subscription</Button>
                                    </center>
                                </div>
                            ) : null}
                            
                        </Col> 
                    </Fragment>
                )}                                   
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {    
        loading: state.members.loading,   
        toast_key: state.members.toast_key,   
        membership: state.members.membership   
    }
}

export default connect(mapStateToProps)(MemberPayment);  
 

