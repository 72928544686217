import { meetConstants } from '../constants';

const initialState = {
    loading: false,
    toast_key:'', 
    meet:[]
};

export default function adminReducer( state=initialState, {type, response}) {

    switch(type) {
        case meetConstants.CREATE_MEETING:
            return { 
                ...state,
                loading:true 
            };
        case meetConstants.CREATE_MEETING_SUCCESS:
            return { 
                ...state, 
                loading:false,                 
                facilitator_meet_url:response.facilitator_meet_url,
                toast_key:response.toast_key,
            };
        case meetConstants.CREATE_MEETING_FAILURE:
            return { 
                ...state,
                loading:false 
            };

        default:
            return state;
    }
};
