import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { createFacilitator } from '../../actions';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';
import FacilitatorForm from './FacilitatorForm';

class FacilitatorCreate extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            userAvator:null
        };

        this.facilitatorformHandler = this.facilitatorformHandler.bind(this);
    }
    facilitatorformHandler(formData){
        this.props.dispatch(createFacilitator(formData));
    }
    render() {
        const { toast_key, history } = this.props;
        const { authRole } = this.state;

        if(toast_key==='facilitator_created'){
            //toast.success("Facilitator created successfully!");
            history.push('/' + authRole + '/facilitator-list');
        }

        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <Link to={'/' + authRole + '/facilitator-list'}><span className="inner-lefticon"><Icofont icon="thin-left" /></span> Home</Link>
                </Col>                
                <FacilitatorForm facilitatorformHandler={this.facilitatorformHandler} data={null}/>   
                <Toaster />                              
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {    
        toast_key: state.facilitattors.toast_key    
    }
}

export default connect(mapStateToProps)(FacilitatorCreate);   
