import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';
import { profileFacilitator, updateFacilitator } from '../../actions';
import FacilitatorForm from './FacilitatorForm';
import LoadingSpinner from '../partials/LoadingSpinner';
import HomeLink from '../partials/HomeLink';

class FacilitatorProfile extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            facilitator_id: this.props.match.params.facilitator_id,
        };

        this.facilitatorformHandler = this.facilitatorformHandler.bind(this)
    }
    componentDidMount() {
        this.props.dispatch(profileFacilitator(this.state.facilitator_id));
    }
    facilitatorformHandler(formData){
        Object.assign(formData, {facilitator_id: this.state.facilitator_id});
        this.props.dispatch(updateFacilitator(formData));
    }
    render() {
        const { authRole } = this.state;
        const { loading, toast_key, data, history } = this.props;

        if(toast_key==='facilitator_updated'){
            //toast.success("Facilitator updated successfully!");
            history.push('/' + authRole + '/facilitator-list');
        }
        else if(toast_key==='myprofile_updated'){
            //toast.success("Facilitator updated successfully!");
            history.push('/' + authRole + '/facilitator-list');
        }

        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <HomeLink role={authRole}/>
                </Col>                                    
                {loading ? <LoadingSpinner /> : <FacilitatorForm facilitatorformHandler={this.facilitatorformHandler} data={data} history={history}/>}                            
                <Toaster />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {        
        toast_key: state.facilitattors.toast_key,    
        data: state.facilitattors.data,    
        loading: state.facilitattors.loading    
    }
}

export default connect(mapStateToProps)(FacilitatorProfile);  
