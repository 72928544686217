import React from 'react';

function LoadingSpinner() {
  	return (
		<div className="loader-wraps">
			<div className="spinner-border spinner-border-sm" role="status">
			  	<span className="visually-hidden">Loading...</span>
			</div>
		</div>
  	);
}

export default LoadingSpinner;
