import { combineReducers } from "redux";
import authReducer from "./authReducer";
import adminReducer from "./adminReducer";
import facilitattorReducer from "./facilitattorReducer";
import groupReducer from "./groupReducer";
import memberReducer from "./memberReducer";
import meetReducer from "./meetReducer";

const rootReducer = combineReducers({
  	auth:authReducer,
  	admins:adminReducer,
  	facilitattors:facilitattorReducer,
  	groups:groupReducer,
  	members:memberReducer,
  	meetings:meetReducer
});

export default rootReducer;
