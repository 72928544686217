import { Link } from 'react-router-dom';
import Icofont from 'react-icofont';

const HomeLink = ({ role }) => {

    let Linker=()=>{
        if(role==='admin') {
            return <Link to={'/' + role + '/dashboard'}><span className="inner-lefticon"><Icofont icon="simple-left" /></span> Home</Link>
        } 
        else {
            return <Link to={'/' + role + '/group-list'}><span className="inner-lefticon"><Icofont icon="simple-left" /></span> Home</Link>
        }
    }

    return <Linker />
};


export default HomeLink;