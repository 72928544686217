import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';
import { profileMember, updateMember } from '../../actions';
import MemberForm from './MemberForm';
import LoadingSpinner from '../partials/LoadingSpinner';

class MemberProfile extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            member_id: this.props.match.params.member_id,
        };

        this.memberformHandler = this.memberformHandler.bind(this)
    }
    componentDidMount() {
        this.props.dispatch(profileMember(this.state.member_id));
    }
    memberformHandler(formData){
        Object.assign(formData, {member_id: this.state.member_id});
        this.props.dispatch(updateMember(formData));
    }
    render() {
        const { authRole } = this.state;
        const { loading, data, toast_key, history } = this.props;

        if(toast_key==='profile_updated'){
            toast.success("Member updated successfully!");
            history.push('/' + authRole + '/member-list');
        }

        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <Link to={'/' + authRole + '/dashboard'}><span className="inner-lefticon"><Icofont icon="thin-left" /></span> Profile</Link>
                </Col>

                {loading ? <LoadingSpinner /> : <MemberForm memberformHandler={this.memberformHandler} data={data}/>}                    
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {        
        data: state.members.data,    
        toast_key: state.members.toast_key,    
        loading: state.members.loading,    
    }
}

export default connect(mapStateToProps)(MemberProfile);  
