import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

const PodCounter = ({hoursMinSecs}) => {
   
    const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
    const [[hrs, mins, secs], setTime] = React.useState([hours, minutes, seconds]);
    const [percentage, setPercentage] = React.useState(hours); 
    
    const tick = () => {
   
        if (hrs === 0 && mins === 0 && secs === 0) 
            reset()
        else if (mins === 0 && secs === 0) {
            setTime([hrs - 1, 59, 59]);
        } else if (secs === 0) {
            setTime([hrs, mins - 1, 59]);
        } else {
            setTime([hrs, mins, secs - 1]);
        }

        setPercentage(hrs + mins);
    };

    const reset = () => setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);

    React.useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });

    
    return (
        <>
            <span className="span-a">Ends in {hrs.toString().padStart(2, '0')} hours {mins.toString().padStart(2, '0')} mins {secs.toString().padStart(2, '0')} Secs</span>   
            <ProgressBar variant="success" now={percentage} /> 
        </>
    );
}

export default PodCounter;