import {put,call,takeLatest} from 'redux-saga/effects'
import { createmeetingApi } from '../../services/meetServices';
import { meetConstants } from '../constants';

export function* createmeetingSaga(payload) {
    try {
        const response = yield call(createmeetingApi, payload.pod_id);
        yield put({ type: meetConstants.CREATE_MEETING_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: meetConstants.CREATE_MEETING_FAILURE, error })
    }
}

export default function* meetSaga() {
    yield takeLatest(meetConstants.CREATE_MEETING, createmeetingSaga);
}