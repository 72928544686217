import React from "react";
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Icofont from 'react-icofont';
import { Formik } from "formik";
import * as Yup from "yup";

const GroupForm = ({groupformHandler, data}) => (

    <Formik
        enableReinitialize
        initialValues={{ 
            name: data?data.name : '', 
            caption: data?data.caption : '', 
            description: data?data.description : ''
        }}
        onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
                setSubmitting(false);
                groupformHandler(values);
            }, 500);
        }}
        validationSchema={Yup.object().shape({
            name: Yup.string().required("Required"),
            caption: Yup.string().required("Required"),
            description: Yup.string().required("Required")
        })}>
        {props => {
            const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit
            } = props;

            return (
                <Form className="page-form" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <h4 className="page-title">Create new group</h4>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Group Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="name" 
                                    id="name" 
                                    value={values.name} 
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                    isValid={touched.name && !errors.name}
                                    className={(errors.name && touched.name ? 'is-invalid' : '')}/>                                   
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Caption</Form.Label>
                                <Form.Control 
                                type="text" 
                                name="caption" 
                                value={values.caption} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.caption && !errors.caption}
                                className={(errors.caption && touched.caption ? 'is-invalid' : '')}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>About group</Form.Label>
                                <Form.Control 
                                as="textarea" 
                                rows={8}
                                type="description" 
                                name="description" 
                                value={values.description} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.description && !errors.description}
                                className={(errors.description && touched.description ? 'is-invalid' : '')}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3 d-none">
                        <Col sm={12}>
                            <ul className="facilitator-group">
                                <li className="open-facilitators">
                                    <div className="icon"><Icofont icon="plus" /></div>
                                    <span>Add Facilitator</span>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12} className="text-end">
                            <Button type="submit" className="submit-link">Save</Button>
                        </Col>
                    </Row>
                </Form>
            );
        }}
    </Formik>
);

export default GroupForm;
