import { facilitatorConstants } from '../store/constants';

export const fetchFacilitators = (sortBy) => {
    return {
        type: facilitatorConstants.FACILITATORS,
        sortBy
    }
};

export const createFacilitator = (formData) => {
    return {
        type: facilitatorConstants.CREATE_FACILITATOR,
        formData
    }
};

export const profileFacilitator = (facilitator_id) => {
    return {
        type: facilitatorConstants.PROFILE_FACILITATOR,
        facilitator_id
    }
};

export const deleteFacilitator = (facilitator_id) => {
    return {
        type: facilitatorConstants.DELETE_FACILITATOR,
        facilitator_id
    }
};

export const updateFacilitator = (formData) => {
    return {
        type: facilitatorConstants.UPDATE_FACILITATOR,
        formData
    }
};

export const toggleFacilitator = (facilitator_id) => {
    return {
        type: facilitatorConstants.TOGGLE_FACILITATOR,
        facilitator_id
    }
};