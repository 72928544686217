import React from "react";
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Formik } from "formik";
import * as Yup from "yup";

const MemberForm = ({memberformHandler, data}) => (
    <Formik
        enableReinitialize
        initialValues={{ 
            firstname: data?data.firstname : '', 
            lastname: data?data.lastname : '', 
            email: data?data.email : '', 
            phone: data?data.phone : '', 
            password: data?data.password : ''
        }}
        onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
                //console.log("Logging in", values);
                setSubmitting(false);
                memberformHandler(values);
            }, 500);
        }}
        validationSchema={Yup.object().shape({
            firstname: Yup.string().required("Required"),
            lastname: Yup.string().required("Required"),
            email: Yup.string().email().required("Required"),
            phone: Yup.string().matches(new RegExp('[0-9]{7}')),
            // password: Yup.string().required("No password provided.")
            // .min(6, "Password is too short - should be 6 chars minimum.")
            // .matches(/(?=.*[0-9])/, "Password must contain a number.")
        })}>
        {props => {
            const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit
            } = props;

            return (
                <Col sm={{ span: 6, offset: 3 }}>
                    <Form className="page-form" onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <h4 className="page-title">Apply for membership</h4>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="firstname" 
                                        id="firstname" 
                                        value={values.firstname} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        isValid={touched.firstname && !errors.firstname}
                                        className={(errors.firstname && touched.firstname ? 'is-invalid' : '')}/>                                   
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control 
                                    type="text" 
                                    name="lastname" 
                                    value={values.lastname} 
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                    isValid={touched.lastname && !errors.lastname}
                                    className={(errors.lastname && touched.lastname ? 'is-invalid' : '')}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Email Id</Form.Label>
                                    <Form.Control 
                                    type="email" 
                                    name="email" 
                                    value={values.email} 
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}
                                    className={(errors.email && touched.email ? 'is-invalid' : '')}/>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control 
                                    type="text" 
                                    name="phone" 
                                    value={values.phone} 
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isValid={touched.phone && !errors.phone}
                                    className={(errors.phone && touched.phone ? 'is-invalid' : '')}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control 
                                    disabled={data? true : false}
                                    placeholder={data? "Disabled" : ""}
                                    type="password" 
                                    name="password" 
                                    value={values.password} 
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                    isValid={touched.password && !errors.password}
                                    className={(errors.password && touched.password ? 'is-invalid' : '')}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={12}>
                                <Form.Group className="custom-document">                                
                                    <Form.Control type="file" id="document" name="document" />
                                    <img className="custom-preview" alt=""/>                                
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4 mt-5 inner-membership">
                            <Col sm={6}>
                                <h4>Membership type</h4>
                                <p>1 Week Free Membership</p>
                            </Col>
                            <Col sm={6}>
                                <h4>Last payment date</h4>
                                <p>Sep 17, 2021</p>
                            </Col>
                        </Row>
                        <Row className="mb-5 inner-membership">
                            <Col sm={6}>
                                <h4>Membership Fee</h4>
                                <p>Free/Week</p>
                            </Col>
                            <Col sm={6}>
                                <h4>Next payment due date</h4>
                                <p>Sep 24, 2021</p>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col sm={12} className="text-end">
                                <Button type="submit" className="submit-link">Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            );
        }}
    </Formik>
);

export default MemberForm;
