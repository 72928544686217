import { facilitatorConstants } from '../constants';

const initialState = {
    toast_key: '',
    loading: false, 
    data:[]
};

export default function facilitatorReducer( state=initialState, {type, response}) {
    switch(type) {
        case facilitatorConstants.FACILITATORS:
            return { 
                ...state,
                loading:true 
            };
        case facilitatorConstants.FACILITATORS_SUCCESS:
            return { 
                ...state, 
                loading:false,
                toast_key:response.toast_key,                 
                data:response.data
            };
        case facilitatorConstants.FACILITATORS_FAILURE:
            return { 
                ...state,
                loading:false 
            };

        case facilitatorConstants.CREATE_FACILITATOR:
            return { 
                ...state,
                loading:true 
            };
        case facilitatorConstants.CREATE_FACILITATOR_SUCCESS:
            return { 
                ...state, 
                loading:false,                 
                toast_key:response.toast_key,             
                data:response.data
            };
        case facilitatorConstants.CREATE_FACILITATOR_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case facilitatorConstants.TOGGLE_FACILITATOR:
            return { 
                ...state,
                loading:false 
            };
        case facilitatorConstants.TOGGLE_FACILITATOR_SUCCESS:
            return { 
                ...state, 
                toggledata:response.toggledata,
                loading:false,                 
            };
        case facilitatorConstants.TOGGLE_FACILITATOR_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case facilitatorConstants.PROFILE_FACILITATOR:
            return { 
                ...state,
                loading:true 
            };
        case facilitatorConstants.PROFILE_FACILITATOR_SUCCESS:
            return { 
                ...state, 
                data:response.data,
                toast_key:response.toast_key, 
                loading:false,                 
            };
        case facilitatorConstants.PROFILE_FACILITATOR_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case facilitatorConstants.UPDATE_FACILITATOR:
            return { 
                ...state,
                loading:true,
            };
        case facilitatorConstants.UPDATE_FACILITATOR_SUCCESS:
            return { 
                ...state, 
                loading:false,                 
                toast_key:response.toast_key,
            };
        case facilitatorConstants.UPDATE_FACILITATOR_FAILURE:
            return { 
                ...state,
                loading:false
            };

        case facilitatorConstants.DELETE_FACILITATOR:
            return { 
                ...state,
                loading:false 
            };
        case facilitatorConstants.DELETE_FACILITATOR_SUCCESS:
            return { 
                ...state, 
                toast_key:response.toast_key,
                loading:false,                 
            };
        case facilitatorConstants.DELETE_FACILITATOR_FAILURE:
            return { 
                ...state,
                loading:false
            };

        default:
            return state;
    }
};
