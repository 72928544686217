import React, {useState} from "react";
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import * as Yup from "yup";
import axios from"axios";
import { appApi } from '../../helpers';
import LoadingSpinner from '../partials/LoadingSpinner';

function MyForm({memberformHandler, avatorHandler, data, member_key, history}) {

    const [preview, setPreview] = useState("");   

    return (<Formik
        enableReinitialize
        initialValues={{ 
            member_key:member_key,
            firstname: data?data.firstname : '', 
            lastname: data?data.lastname : '', 
            email: data?data.email : '', 
            phone: data?data.phone : '', 
            password: '',
            file:''
        }}
        onSubmit={(values, { setErrors, setSubmitting }) => {
            let token = localStorage.getItem('token');
            setTimeout(() => {
                (async () => {
                    try {
                        const result = await axios.post(appApi + "member/my-profile-update", values, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            },
                        });
                        history.push('/register-success');                       
                    } catch (exception) {
                        // Expected: 400 status code
                        if (exception.response && exception.response.status === 400) {
                            let form_errors=exception.response.data.errors;
                            setErrors(form_errors);
                            setSubmitting(false);
                        }
                    }
                })();
            }, 500);
        }}
        >
        {props => {
            const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
            } = props;

            const membership=data?data.my_membership : [];

            return (
                <Form className="page-form" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Row className="mb-3 align-items-center">
                        <Col sm={2}>
                            <Form.Group className="custom-document">                                
                                <Form.Control type="file" id="file" name="file" onChange={(event) => {
                                    const fileReader = new FileReader();
                                    fileReader.onload = () => {
                                        if (fileReader.readyState === 2) {    
                                            setPreview(fileReader.result);
                                        }
                                    };
                                    fileReader.readAsDataURL(event.target.files[0]);

                                    setFieldValue("avator", event.currentTarget.files[0]);
                                }}
                                />
                                { preview!==""? (
                                    <img className="custom-preview" alt="" src={preview}/> 
                                    ) : (
                                    <img className="custom-preview" alt="" src={data?data.avator : null }/> 
                                )} 
                                <Form.Control.Feedback type="invalid">{errors.file}</Form.Control.Feedback>                       
                            </Form.Group>
                        </Col> 
                        <Col sm={10}>
                            <h4 className="page-title">Click to change your image</h4>
                        </Col>        
                    </Row>
                    <Row className="mb-3">
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="firstname" 
                                    id="firstname" 
                                    value={values.firstname} 
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                    isValid={touched.firstname && !errors.firstname}
                                    className={(errors.firstname && touched.firstname ? 'is-invalid' : '')}/>    
                                    <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>                                
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control 
                                type="text" 
                                name="lastname" 
                                value={values.lastname} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.lastname && !errors.lastname}
                                className={(errors.lastname && touched.lastname ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>  
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Email Id</Form.Label>
                                <Form.Control 
                                type="email" 
                                name="email" 
                                value={values.email} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.email && !errors.email}
                                className={(errors.email && touched.email ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback> 
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control 
                                type="text" 
                                name="phone" 
                                value={values.phone} 
                                onChange={handleChange}
                                onBlur={handleBlur} 
                                isValid={touched.phone && !errors.phone}
                                className={(errors.phone && touched.phone ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback> 
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control 
                                type="password" 
                                name="password" 
                                value={values.password} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                isValid={touched.password && !errors.password}
                                className={(errors.password && touched.password ? 'is-invalid' : '')}/>
                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3 d-none">
                        <Col sm={12}>
                            <Form.Group className="custom-document">                                
                                <Form.Control type="file" id="document" name="document" />
                                <img className="custom-preview" alt=""/>                                
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-4 mt-5 inner-membership">
                        <Col sm={6}>
                            <h4>Membership type</h4>
                            <p>{membership?membership.membership_type==='trail'? '1 Week Free Membership':'1 Week Premium Membership' :'Free'} {membership?membership.membership_type==='trail'? (
                                    <Link to={'/member/member-payment/' + member_key} className="subscribe-link">Change Membership</Link>
                                ) : (
                                    <Link to={'/member/member-payment/' + member_key} className="subscribe-link">Change Membership</Link>
                                ) : (
                                    <Link to={'/member/member-payment/' + member_key} className="subscribe-link">Change Membership</Link>
                                )}</p>                            
                        </Col>
                        <Col sm={6}>
                            <h4>Membership Fee</h4>
                            <p>{membership?membership.membership_type==='trail'? 'Free/Week':'Premium/Week' :'Free/Week'}</p>
                        </Col>
                    </Row>
                    <Row className="mb-5 inner-membership">
                        <Col sm={6}>
                            <h4>Last payment date</h4>
                            <p>{membership?membership.payment_date:'-'}</p>
                        </Col>                        
                        <Col sm={6}>
                            <h4>Next payment due date</h4>
                            <p>{membership?membership.next_payment_date:'-'}</p>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12} className="text-end">
                            <Button type="submit" className="submit-link">Update</Button>
                        </Col>
                    </Row>
                </Form>
            );
        }}
    </Formik>
    )
};

export default MyForm;
