import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Icofont from 'react-icofont';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { fetchGroupinfo, addFacilitator, removeFacilitator, subscribeGroup, unsubscribeGroup } from '../../actions';
import LoadingSpinner from '../partials/LoadingSpinner';
import toast, { Toaster } from 'react-hot-toast';
import HomeLink from '../partials/HomeLink';
import { Scrollbars } from 'react-custom-scrollbars';

class GroupInfo extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            authRole:localStorage.getItem('role'),
            facilitatorDropdown:false,
            group_id:this.props.match.params.id,
            adminDiv:{
                'span': 12, 
            },
            facilitatorDiv:{
                'span': 10,
                'offset': 1
            },
            group_info:[],
            group_facilitators:[],
            toast_key:'',
            group_subscription:false
        };

        this.toggleFacilitator = this.toggleFacilitator.bind(this);
        this.selectfacilitatorHandler = this.selectfacilitatorHandler.bind(this);
        this.removefacilitatorHandler = this.removefacilitatorHandler.bind(this);
        this.subscribeHandler = this.subscribeHandler.bind(this);
        this.unsubscribeHandler = this.unsubscribeHandler.bind(this);
    }
    componentDidMount() {
        var timedifference = new Date().getTimezoneOffset();
        this.props.dispatch(fetchGroupinfo(this.state.group_id));        
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.data !== prevProps.data) {
            this.setState({
                group_info:this.props.data,
                group_facilitators:this.props.data.group_facilitators,
                group_subscription:this.props.data.group_subscription
            });
        }
        if (this.props.group_facilitators !== prevProps.group_facilitators) {
            this.setState({
                group_facilitators:this.props.group_facilitators
            });
        }
        if (this.props.toast_key !== prevProps.toast_key) {
            this.setState({
                toast_key:this.props.toast_key
            });
        }
    }
    toggleFacilitator() {
        const currentState = this.state.facilitatorDropdown;
        this.setState({ 
            facilitatorDropdown: !currentState 
        });
    }
    selectfacilitatorHandler(facilitator_id, username){
        const currentState = this.state.facilitatorDropdown;
        this.setState({
            facilitatorDropdown: !currentState 
        });

        const formData = {
            group_id:this.state.group_id, 
            facilitator_id,
            username
        };

        this.props.dispatch(addFacilitator(formData));
    }
    removefacilitatorHandler(facilitator_id){
        const formData = {
            group_id:this.state.group_id, 
            facilitator_id
        };
        
        toast((t) => (
            <span>
                Confirm to <b>Remove</b> <span>&nbsp; </span>
                    <Button variant="danger" size="sm" onClick={(event) => {
                        toast.dismiss(t.id);
                        
                        let filteredArray = this.state.group_facilitators.filter(item => item.user_id !== facilitator_id);
                        this.setState({group_facilitators: filteredArray});
                        this.props.dispatch(removeFacilitator(formData));
                    }}>
                    Confirm
                    </Button>
            </span>
        ));
    }
    subscribeHandler(group_id, currentStatus){
        this.props.dispatch(subscribeGroup(group_id));
        this.setState({
            group_subscription: !currentStatus 
        });

        toast.success("Group subscribed!");
    }
    unsubscribeHandler(group_id, currentStatus){
        this.props.dispatch(unsubscribeGroup(group_id));
        this.setState({
            group_subscription: false 
        });

        toast.success("Group unscubscribed!");
    }
    render() {
        const { user, group_id, authRole, facilitatorDropdown, adminDiv, facilitatorDiv, group_info, group_facilitators, toast_key, group_subscription } = this.state;
        const { loading } = this.props;

        const GroupDetails = () => {
            return(
                <Col sm={ authRole==='admin'? adminDiv : facilitatorDiv } className="group-info">
                    <Row className="mb-2">
                        <Col sm={authRole==='admin' || authRole==='member'? 12 : 10}>
                            <p><i>Group Name</i></p>
                            <h4>{group_info.name}</h4>
                        </Col>
                    </Row>    
                    <Row className="mb-3">
                        <Col sm={authRole==='admin' || authRole==='member'? 12 : 10}>
                            <p><i>About the group</i></p>
                            <p className="group-p">{group_info.description}</p>
                        </Col>
                    </Row> 
                    <Row className="mb-2 align-items-center">
                        <Col sm={authRole==='admin' || authRole==='member'? 12 : 10} className="mb-2">
                            <p><i>Facilitators of the group</i></p>
                        </Col>
                        <Col sm={authRole==='admin' || authRole==='member'? 12 : 10}>
                            <Row>
                                <Col sm={9}>
                                    <ul className="facilitator-group">
                                        { group_facilitators? (
                                            group_facilitators.map((item, i)=>{
                                                return(
                                                    <li key={i}>
                                                        <div>
                                                            { authRole==='admin'? (
                                                                <label onClick={() => this.removefacilitatorHandler(item.user_id)}><Icofont icon="minus-circle" /></label>
                                                            ) : <></>} 
                                                            <OverlayTrigger
                                                                key={"tooltip_" + i}
                                                                placement={"top"}
                                                                overlay={
                                                                    <Tooltip id={`tooltipu_${i}`}>
                                                                        {item.username}
                                                                    </Tooltip>
                                                                }
                                                                >
                                                                <img src={item.group_avator} alt="avator" /> 
                                                            </OverlayTrigger>                                                                                                                    
                                                        </div>
                                                        <span className="d-none">{item.username}</span>
                                                    </li>
                                                )
                                            })
                                        ) : <p>No result</p> }

                                        { authRole==='admin'? (
                                            <li key="z" className="open-facilitators">
                                                <div className="icon" onClick={this.toggleFacilitator}><Icofont icon="plus" /></div>
                                                    <span>Add Facilitator</span>
                                                    { facilitatorDropdown ? <Fragment>                                                    
                                                        <section className="facilitators-box">
                                                            <h4>Select Facilitator</h4>
                                                            <Row>
                                                                { group_info.AllFacilitators? (
                                                                    group_info.AllFacilitators.map((facili, i)=>{
                                                                        return(
                                                                            <Col key={i} sm={12} className="mb-2 pt-2 pb-2 col-box" onClick={() => this.selectfacilitatorHandler(facili.id, facili.name)}>
                                                                                <div className="flex-avator">
                                                                                    <img src={facili.avator} alt="avator" /> 
                                                                                </div>
                                                                                <div className="flex-name">{facili.name}</div>
                                                                            </Col>
                                                                        )
                                                                    })
                                                                ) : <p>No result</p> }                                                            
                                                            </Row>
                                                        </section>
                                                    </Fragment>
                                                : null
                                                }                                        
                                            </li>
                                            ) : <></>}                                        
                                    </ul>
                                </Col>
                                <Col sm={3} className="text-end">
                                    { authRole==='admin' || authRole==='facilitator'? (
                                        <Link className="new-link" to={'/' + authRole + '/pod-create/' + group_info.id}>Create Pod</Link>
                                    ) : null } 
                                    { authRole==='member' && group_info.stripe_subscription===true && group_subscription===true ? (
                                        <Link className="new-link" to="#" onClick={() => this.unsubscribeHandler(group_info.id, group_info.group_subscription)}>Unfollow</Link>
                                    ) : null} 
                                    { authRole==='member' && group_info.stripe_subscription===true && group_subscription===false ? (
                                        <Link className="new-link" to="#" onClick={() => this.subscribeHandler(group_info.id, group_info.group_subscription)}>Follow</Link>
                                    ) : null}
                                    { authRole==='member' && group_info.stripe_subscription===false ? (
                                        <Link className="new-link" to="#" to={'/member/member-payment/' + user.member_key}>Follow</Link>
                                    ) : null}
                                </Col>
                            </Row>
                        </Col>                        
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12} className="mb-3">
                            <h4>Your Pods</h4>
                        </Col>
                        <Row>
                            <Col sm={authRole==='admin'? 3 : 4}>
                                <div className="inner-pods upcoming-pods">
                                    <h4 className="mb-3">Upcoming Pods <span className="float-end">Pods:{group_info.group_pods_upcoming?group_info.group_pods_upcoming.length : 0}</span></h4>
                                    <Scrollbars style={{ width: '100%', height: '280px' }}
                                        autoHide
                                        autoHideTimeout={1000}
                                        autoHideDuration={200}
                                        thumbMinSize={10}> 
                                        { group_info.group_pods_upcoming? (
                                        group_info.group_pods_upcoming.map((upcoming, i)=>{
                                            let infolink='/' + authRole + '/pod-info/'+ group_id +'/' + upcoming.id;
                                            if(authRole==="member" && group_info.stripe_subscription===false){
                                                infolink='/member/member-payment/' + user.member_key;
                                            }
                                            return(
                                                <Link to={infolink} className="pods" key={i} data-repeat={"1/4"}>
                                                    <h4>{upcoming.topic}</h4>
                                                    <p>At Date {upcoming.event_start} time {upcoming.time} {"gmt " + upcoming.timezone}</p>
                                                    <div className="flex-data">
                                                        <img src={upcoming.pod_avator} alt="avator" /> <span className="pod_avator">{upcoming.pod_own_by}e</span>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    ) : <p>No result</p> }
                                    </Scrollbars>
                                </div>
                            </Col>
                            <Col sm={authRole==='admin'? 3 : 4}>
                                <div className="inner-pods today-pods">
                                    <h4 className="mb-3">Todays Pods <span className="float-end">Pods:{group_info.group_pods_today?group_info.group_pods_today.length : 0}</span></h4>
                                    <Scrollbars style={{ width: '100%', height: '280px' }}
                                        autoHide
                                        autoHideTimeout={1000}
                                        autoHideDuration={200}
                                        thumbMinSize={10}> 
                                            { group_info.group_pods_today? (                                        
                                            group_info.group_pods_today.map((today, i)=>{
                                                let infolink='/' + authRole + '/pod-info/'+ group_id +'/' + today.id;
                                                if(authRole==="member" && group_info.stripe_subscription===false){
                                                    infolink='/member/member-payment/' + user.member_key;
                                                }
                                                return(
                                                    <Link to={infolink} className="pods" key={i} data-repeat={"1/4"}>
                                                        <h4>{today.topic}</h4>
                                                        <p>At Date {today.event_start} time {today.time} {"gmt " + today.timezone}</p>
                                                        <div className="flex-data">
                                                            <img src={today.pod_avator} alt="avator" /> <span className="pod_avator">{today.pod_own_by}</span>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        ) : <p>No result</p> }
                                    </Scrollbars>
                                </div>
                            </Col>
                            <Col sm={authRole==='admin'? 3 : 4}>
                                <div className="inner-pods completed-pods">
                                    <h4 className="mb-3">Completed <span className="float-end">Pods:{group_info.group_pods_completed?group_info.group_pods_completed.length : 0}</span></h4>
                                    <Scrollbars style={{ width: '100%', height: '280px' }}
                                        autoHide
                                        autoHideTimeout={1000}
                                        autoHideDuration={200}
                                        thumbMinSize={10}> 
                                            { group_info.group_pods_completed? (
                                                group_info.group_pods_completed.map((completed, i)=>{
                                                    let infolink='/' + authRole + '/pod-info/'+ group_id +'/' + completed.id;
                                                    if(authRole==="member" && group_info.stripe_subscription===false){
                                                        infolink='/member/member-payment/' + user.member_key;
                                                    }
                                                    return(
                                                        <Link to={infolink} className="pods" key={i} data-repeat={"1/4"}>
                                                            <h4>{completed.topic}</h4>
                                                            <p>At Date {completed.event_start} time {completed.time} {"gmt " + completed.timezone}</p>
                                                            <div className="flex-data">
                                                                <img src={completed.pod_avator} alt="avator" /> <span className="pod_avator">{completed.pod_own_by}</span>
                                                            </div>
                                                        </Link>
                                                    )
                                                })
                                            ) : <p>No result</p> }
                                    </Scrollbars>
                                </div>
                            </Col>
                            {authRole==='admin'? <Col sm={3}>
                                <div className="inner-box">
                                    <ul>
                                        <div>Insights</div>
                                        <li>Subscribers</li>
                                        <li>Active Pods</li>
                                        <li>InActive Pods</li>
                                        <li>Facilitators</li>
                                    </ul>
                                    <Link className="new-link" to={'/' + authRole + '/group-edit/' + group_id}>Edit This Group</Link>
                                </div>
                            </Col> : null}                            
                        </Row>
                    </Row>
                </Col> 
            )
        }

        return (
            <Fragment>            
                <Col sm={12} className="home-title">
                    <HomeLink role={authRole}/>
                </Col>
                {loading ? <LoadingSpinner /> : <GroupDetails/>} 
                <Toaster />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.groups.loading,     
        data: state.groups.data,        
        group_facilitators: state.groups.group_facilitators,        
        toast_key: state.groups.toast_key  
    }
}
  
export default connect(mapStateToProps)(GroupInfo);  
