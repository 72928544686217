import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';
import facilitator_bg from './facilitator_bg.png';
import member_bg from './member_bg.png';
import admin_bg from './admin_bg.png';
import group_bg from './group_bg.png';


class Dashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
        };
    }
    componentDidMount() {
        
    }
    render() {

        const { authRole } = this.state;
        
        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <Link to={'/' + authRole + '/dashboard'}><span className="inner-lefticon"><Icofont icon="thin-left" /></span> Home</Link>
                </Col>
                <Col sm={{ span: 6, offset: 3 }} className="f-height">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <Row className="align-items-center">
                                <Link to="/admin/facilitator-list" className="admin-box fa-box mb-1">
                                    <span>Facilitators</span>
                                </Link>  
                            </Row>                              
                        </Col>
                        <Col sm={6}>
                            <Row className="align-items-center">
                                <Link to="/admin/member-list" className="admin-box me-box mb-1">
                                    <span>Members</span>
                                </Link>  
                            </Row> 
                        </Col>
                        <Col sm={6}>
                            <Row className="align-items-center">
                                <Link to="/admin/admin-list" className="admin-box adi-box mb-4">
                                    <span>Admins</span>
                                </Link>  
                            </Row>  
                        </Col>
                        <Col sm={6}>
                            <Row className="align-items-center">
                                <Link to="/admin/group-list" className="admin-box gr-box mb-4">
                                    <span>Groups</span>
                                </Link>  
                            </Row>  
                        </Col>
                    </Row>
                </Col>  
            </Fragment>                         
        )
    }
}

export default Dashboard

