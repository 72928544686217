import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Icofont from 'react-icofont';
import { Scrollbars } from 'react-custom-scrollbars';
import { fetchMembers, toggleMember, deleteMember } from '../../actions';
import LoadingSpinner from '../partials/LoadingSpinner';

class MemberList extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            members:[],
            totalMembers:0,
            activeMembers:0,
            inactiveMembers:0
        };

        this.toggleHandler = this.toggleHandler.bind(this);
        this.filterHandler = this.filterHandler.bind(this);
    }
    componentDidMount() {
        this.props.dispatch(fetchMembers("name"));
    }
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                members:this.props.data,
            });
        }
    }
    toggleHandler(member_id, status){
        this.props.dispatch(toggleMember(member_id));

        let newstatus=status==="active"? "in_active" : "active";
        this.setState(prevState => ({
            members: prevState.members.map(
                el => el.id === member_id? { ...el, status: newstatus }: el
            )
        }));

        toast.success("Member status changed successfully!");
    }
    deleteHandler(member_id){
        toast.error("Delete function currently Disabled!");
        // toast((t) => (
        //     <span>
        //         Confirm to <b>Delete</b> &nbsp;
        //         <Button variant="danger" size="sm" onClick={(event) => {
        //             toast.dismiss(t.id);
        //             this.props.dispatch(deleteMember(member_id));
        //             let filteredArray = this.state.members.filter(item => item.id !== member_id)
        //             this.setState({members: filteredArray});
        //             toast.success("Member Deleted!");
        //             }}>
        //             Confirm
        //         </Button>
        //     </span>
        // ));
    }
    filterHandler(e){
        console.log(e.target.value);
        this.props.dispatch(fetchMembers(e.target.value));
    }
    render() {
        const { loading, toggleStatus } = this.props;
        const { members, totalMembers, activeMembers, inactiveMembers, authRole} = this.state;

        // if(toggleStatus){
        //     toast.success("Member status has been updated!");
        // }

        const MemberTable = () => {
            return(
                <div className="table-responsive">                              
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Member Since</th>
                                <th>Approval Status</th>
                                <th>Payment Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members && members.length > 0 ? members.map(member => (
                                <tr key={member.id}>
                                    <td data-label="">
                                        <div className="flex-avator">&nbsp;</div>
                                    </td>
                                    <td data-label="Name">{member.name}</td>
                                    <td data-label="Email"><Link className="line-link" to={'/' + authRole + '/member-profile/' + member.id}>{member.email}</Link></td>                            
                                    <td data-label="Member Since">{member.created_at}</td>
                                    <td data-label="Approval Status"><span onClick={() => this.toggleHandler(member.id, member.status)} className={member.status}>{(member.status==='active')? 'Approved' : 'Pending'}</span></td>
                                    <td data-label="Payment Status">Trail</td>
                                    <td data-label="Action"><span onClick={() => this.deleteHandler(member.id)} className="del-icon"><Icofont icon="ui-delete" /></span></td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="6">
                                        Empty Members ...
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        }
            
        return (
            <Fragment>                
                <Col sm={12} className="home-title">
                    <Link to={'/' + authRole + '/dashboard'}><span className="inner-lefticon"><Icofont icon="thin-left" /></span> Home</Link>
                </Col>
                <Col sm={{ span: 10, offset: 1 }}>
                    <Row>
                        <Col sm={9} className="inner-scroll">
                            <Row className="mb-2 align-items-center">
                                <Col sm={8} className="table-title">Members</Col>
                                <Col sm={2} className="text-end font-xs">Sort By</Col>
                                <Col sm={2} className="whitu">
                                    <Form.Select size="sm" onChange={this.filterHandler}>
                                        <option value="name">Name</option>
                                        <option value="email">Email</option>
                                        <option value="status">Status</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Scrollbars style={{ width: '100%', height: '90%' }}
                            autoHide={false}
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            thumbMinSize={20}
                            thumbSize={20}
                            hideTracksWhenNotNeeded={false}>  
                                {loading ? <LoadingSpinner /> : <MemberTable/>}
                            </Scrollbars>
                        </Col>
                        <Col sm={3}>
                            <div className="inner-box">
                                <ul>
                                    <div>Members</div>
                                    <li>Total <span>{totalMembers}</span></li>
                                    <li>Active <span>{activeMembers}</span></li>
                                    <li>In Active <span>{inactiveMembers}</span></li>
                                </ul>
                                <Link className="new-link" to={'/' + authRole + '/member-create'}>Add Member</Link>
                            </div>
                        </Col>
                    </Row>
                </Col> 
                <Toaster />                                       
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.members.loading,     
        data: state.members.data.data,
        toggleStatus: state.members.toggleStatus    
    }
}

export default connect(mapStateToProps)(MemberList); 

