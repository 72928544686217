export const authConstants = {
    VERIFY_REQUEST: 'USERS_VERIFY_REQUEST',
    VERIFY_SUCCESS: 'USERS_VERIFY_SUCCESS',
    VERIFY_FAILURE: 'USERS_VERIFY_FAILURE',
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',    
    LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',   
    LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE'    
};