import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';
import { Scrollbars } from 'react-custom-scrollbars';
import { fetchGroups } from '../../actions';
import YourWeek from './YourWeek';
import LoadingSpinner from '../partials/LoadingSpinner';
import HomeLink from '../partials/HomeLink';

class GroupList extends Component {
    constructor(props){
        super(props);
        this.state = {
            user:JSON.parse(localStorage.getItem('user')),
            authRole:localStorage.getItem('role'),
        };
    }
    componentDidMount() {
        this.props.dispatch(fetchGroups());
    }
    render() {
        const { your_week, data, loading, history } = this.props;
        const { authRole, user } = this.state;

        console.log(history);

        const GroupTable = () => {
            return(                    
                <ul className="group-list">
                    {data && data.length > 0 ? data.map(admin => (
                        <li key={admin.id}>
                            <Link className="link-groupinfo" to={'/' + authRole + '/group-info/' + admin.id}>
                                <h4>{admin.name}</h4>
                                <p>{admin.caption}</p>
                                <span><Icofont icon="thin-right" /></span>
                            </Link>
                        </li>
                    )) : (
                        <div>
                            <p>No group available...</p>
                        </div>
                    )}
                </ul>
            )
        }

        return (
            <Row className="full-height pos-rel"> 
                <Col sm={12} className="home-title">
                    <HomeLink role={authRole}/>
                </Col>                
                <Col sm={{ span: authRole==='admin'? 8 : 6, offset: authRole==='admin'? 2 : 3 }}>
                    <Row>
                        { authRole==='member'? (
                            <Col className="text-center mb-4">
                                <h3 className="fs-5">Good Morning {user.name} <br />Welcome to Social pods</h3>
                            </Col>
                            ) : <></>}
                        
                        <Col sm={authRole==='admin'? 8 : 12} className="inner-scroll">
                            <h4 className="mb-4">Available Groups</h4>
                            <Scrollbars style={{ width: '100%', height: '90%' }}
                            autoHide={false}
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            thumbMinSize={20}
                            thumbSize={20}
                            hideTracksWhenNotNeeded={false}>                                
                                {loading ? <LoadingSpinner /> : <GroupTable/>}
                            </Scrollbars>
                        </Col>
                        { authRole==='admin'? (
                            <Col sm={4}>
                                <div className="inner-box">
                                    <ul>
                                        <div>Insights</div>
                                        <li>Total Facilitators</li>
                                        <li>Total Groups</li>
                                        <li>Total Pods</li>
                                        <li>Active Pods</li>
                                        <li>InActive Pods</li>
                                    </ul>
                                    <Link className="new-link" to={'/' + authRole + '/group-create'}>Create Group</Link>
                                </div>
                            </Col>
                            ) : (
                                <></>
                            )
                        }                        
                    </Row>
                </Col>  
                { authRole!=='admin'? <YourWeek your_pods={your_week} /> : <></>}                
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.groups.loading,     
        data: state.groups.data.data,
        your_week: state.groups.your_week,     
    }
}
  
export default connect(mapStateToProps)(GroupList);   
