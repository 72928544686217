import React, { Component } from 'react';

class Forgot extends Component {
    render() {
        return (
            <h4>Forgot dfdffd Password</h4>
        )
    }
}
  
export default Forgot;
