import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';
import subscriptionMiddleware from './middlewares/subscriptionMiddleware'

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    return {
        ...createStore(rootReducer, applyMiddleware(sagaMiddleware, subscriptionMiddleware)),
        runSaga: sagaMiddleware.run(rootSaga)
    }
};

export default configureStore;