import { fork } from 'redux-saga/effects';
import authSaga from './authSaga';
import adminSaga from './adminSaga';
import facilitatorSaga from './facilitatorSaga';
import groupSaga from './groupSaga';
import memberSaga from './memberSaga';
import meetSaga from './meetSaga';

export default function* rootSaga(){
	yield fork(authSaga);
	yield fork(adminSaga);
	yield fork(facilitatorSaga);
	yield fork(groupSaga);
	yield fork(memberSaga);
	yield fork(meetSaga);
}