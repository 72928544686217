import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { appRegister } from '../../actions';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';
import RegisterForm from './RegisterForm';
import LoadingSpinner from '../partials/LoadingSpinner';

class Register extends Component {
    constructor(props){
        super(props);
        this.state = {
            authRole:localStorage.getItem('role'),
            userAvator:null
        };

        this.registerformHandler = this.registerformHandler.bind(this)
    }
    registerformHandler(formData){
        this.props.dispatch(appRegister(formData));
    }
    render() {
        const { loading, toast_key, history, errors } = this.props;
        if(toast_key==='user_created'){
            toast.success("Member created successfully!");
            history.push('/register-success');
        }
        
        return (
            <Fragment>
                <Col sm={12} className="home-title">
                    <Link to={'/' + this.state.authRole + '/dashboard'}><span className="inner-lefticon"><Icofont icon="thin-left" /></span> Home</Link>
                </Col>
                <Col sm={{ span: 6, offset: 3 }}>
                    {loading ? <LoadingSpinner /> : <RegisterForm registerformHandler={this.registerformHandler} history={history}/>}                    
                </Col>                                 
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {    
        loading: state.auth.loading,
        toast_key: state.auth.toast_key,
        user: state.auth.user,
        errors: state.auth.errors
    }
}

export default connect(mapStateToProps)(Register);   
