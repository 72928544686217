import {put,call,takeLatest} from 'redux-saga/effects'
import { alladminApi } from '../../services/adminServices';
import { adminConstants } from '../constants';

export function* alladminSaga(payload) {
    try {
        const response = yield call(alladminApi, payload);
        yield put({ type: adminConstants.ADMINS_SUCCESS, response })
        
    } catch(error) {
        yield put({ type: adminConstants.ADMINS_FAILURE, error })
    }
}

export default function* adminSaga() {
    yield takeLatest(adminConstants.ADMINS, alladminSaga);
}